import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { addChapter, updateChapter, deleteChapter ,getAllSubjects} from '../redux/actions/subjectActions';
import useStyles from '../styles/ChaptersModal';

import { useEffect } from 'react';

const initialChapterDetails = {
  name: '',
  number: '',
  ch_monthly_price:'',
  ch_quarterly_price:'',
  ch_yearly_price:''
};

const ChaptersModal = (props) => {
  const { setChaptersModal, subject } = props;
  subject.chapters.sort((a, b) => (a.number - b.number));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [chapterDetails, setChapterDetails] = useState(initialChapterDetails);
  const [selectedChapter, setSelectedChapter] = useState('');
  const [editChapterModal, setEditChapterModal] = useState(false);
 
  
  const classes = useStyles();
  
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setChapterDetails({ ...chapterDetails, [name]: e.target.value });
  };

  const handleEditChange = (e) => {
    const { name } = e.target;
    setSelectedChapter({ ...selectedChapter, [name]: e.target.value });
  };

  const handleEditClick = (chapter) => {
    setSelectedChapter({ ...chapter });
    setEditChapterModal(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteChapter(id, subject.id));
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    dispatch(updateChapter(selectedChapter.id, selectedChapter)).then(()=>{
      dispatch(getAllSubjects());
      
    setEditChapterModal(false);
    
    })
 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    dispatch(addChapter({ ...chapterDetails, courseId: subject.id })).then(()=>{
      dispatch(getAllSubjects());
      setChapterDetails(initialChapterDetails);

    })
   
  };

  return (
    <div className={classes.chaptersModal}>
      <div className={classes.chaptersModalContent}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setChaptersModal(false)}
        />
        <div className={classes.chaptersModalContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Add chapter
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
          >
            <DialogTitle className={classes.chapterDialogTitle}>Add Chapter</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  required
                  name="number"
                  type="number"
                  label="Chapter number"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={chapterDetails.number}
                  className={classes.boardModalInput}
                  onChange={handleChange}
                />
                <TextField
                  required
                  name="name"
                  label="Chapter name"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={chapterDetails.name}
                  className={classes.boardModalInput}
                  onChange={handleChange}
                />


<TextField
                  required
                  name="ch_monthly_price"
                  label="Monthly Price"
                  size="small"
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={chapterDetails.ch_monthly_price}
                  className={classes.boardModalInput}
                  onChange={handleChange}
                />

<TextField
                  required
                  type="number"
                  name="ch_quarterly_price"
                  label="Quarterly Price"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={chapterDetails.ch_quarterly_price}
                  className={classes.boardModalInput}
                  onChange={handleChange}
                />

<TextField
                  required
                  type="number"
                  name="ch_yearly_price"
                  label="Yearly price"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={chapterDetails.ch_yearly_price}
                  className={classes.boardModalInput}
                  onChange={handleChange}
                />


        

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <Dialog
            open={editChapterModal}
            onClose={() => setEditChapterModal(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
          >
            <DialogTitle className={classes.chapterDialogTitle}>Edit Chapter</DialogTitle>
            <form onSubmit={handleSaveChanges}>
              <DialogContent>
                <TextField
                  required
                  name="number"
                  type="number"
                  label="Chapter number"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={selectedChapter.number}
                  className={classes.boardModalInput}
                  onChange={handleEditChange}
                />
                <TextField
                  required
                  name="name"
                  label="Chapter name"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={selectedChapter.name}
                  className={classes.boardModalInput}
                  onChange={handleEditChange}
                />
                
<TextField
                  required
                  name="ch_monthly_price"
                  label="Monthly Price"
                  size="small"
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={selectedChapter.ch_monthly_price}
                  className={classes.boardModalInput}
                  onChange={handleEditChange}
                />

<TextField
                  required
                  type="number"
                  name="ch_quarterly_price"
                  label="Quarterly Price"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={selectedChapter.ch_quarterly_price}
                  className={classes.boardModalInput}
                  onChange={handleEditChange}
                />

<TextField
                  required
                  type="number"
                  name="ch_yearly_price"
                  label="Yearly price"
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={selectedChapter.ch_yearly_price}
                  className={classes.boardModalInput}
                  onChange={handleEditChange}
                />


              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEditChapterModal(false)} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Chapter table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Chapter Number</TableCell>
                  <TableCell align="center">Chapter Name</TableCell>
                  <TableCell align="center">Monthly Price</TableCell>
                  <TableCell align="center">Quarterly Price</TableCell>
                  <TableCell align="center">Yearly Price</TableCell>
                  <TableCell align="center">Edit</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subject?.chapters.map((chapter,index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{chapter.number}</TableCell>
                    <TableCell align="center">{chapter.name}</TableCell>
                    <TableCell align="center">{chapter.ch_monthly_price}</TableCell>
                    <TableCell align="center">{chapter.ch_quarterly_price}</TableCell>
                    <TableCell align="center">{chapter.ch_yearly_price}</TableCell>
                    <TableCell align='center'>
                      <EditIcon
                        className={classes.editIcon}
                        onClick={() => handleEditClick(chapter)}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <DeleteIcon
                        className={classes.deleteIcon}
                        onClick={() => handleDelete(chapter.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div >
  );
};

export default ChaptersModal;
