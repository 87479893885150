
import { combineReducers } from 'redux';
import BulkUserReducer from './BulkUserReducer';
import boardReducer from './boardReducer';
import adminReducer from './adminReducer';
import subjectReducer from './subjectReducer';
import standardReducer from './standardReducers';
import eventReducer from './eventReducer';
import workshopReducer from './workshopReducer';
import testimonialReducer from './testimonialReducer';
import blogReducer from './blogReducer';
import userReducer from './userReducer';
import lectureReducer from './lectureReducer';
import teacherReducer from './teacherReducer';
import projectReducer from './projectReducer';
import examsReducer from './examsReducer';

export default combineReducers({
  boardReducer,
  adminReducer,
  subjectReducer,
  standardReducer,
  eventReducer,
  workshopReducer,
  testimonialReducer,
  blogReducer,
  userReducer,
  lectureReducer,
  teacherReducer,
  projectReducer,
  examsReducer,
  BulkUserReducer
});
