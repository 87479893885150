import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import useStyles from '../styles/UsersListPage';
import PurchasedEventsTable from './PurchasedEventsTable';
import PurchasedCoursesTable from './PurchasedCoursesTable';
import PurchasedWorkshopsTable from './PurchasedWorkshopsTable';

const UserPurchases = (props) => {
  const classes = useStyles();
  const { setShowPurchases, purchases, userId } = props;
  console.log('purchases', purchases)

  return (
    <div className={classes.ListPageContainer}>
      <div className={classes.buttonContainer}>
        <Button
          color='primary'
          startIcon={<ArrowBackIosIcon />}
          onClick={() => setShowPurchases(false)}
        >
          Back
        </Button>
      </div>
      <PurchasedEventsTable events={purchases.events} userId={userId} />
      <PurchasedWorkshopsTable
        projects={purchases.projects}
        userId={userId}
      />
      <PurchasedCoursesTable courses={purchases.courses} userId={userId} />
    </div>
  );
};

export default UserPurchases;
