import { GET_ALL_BLOGS, ADD_BLOG, UPDATE_BLOG, DELETE_BLOG } from '../actionTypes';

const initialState = {
  allBlogs: [],
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BLOGS:
      return {
        ...state,
        allBlogs: action?.payload,
      };

    case DELETE_BLOG:
      return {
        ...state,
        allBlogs: state.allBlogs.filter(blog => blog.id !== action.payload)
      };

    case ADD_BLOG:
    case UPDATE_BLOG:
      return state;

    default:
      return state;
  }
};

export default blogReducer;
