import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { uploadNotes, uploadLecture } from '../redux/actions/subjectActions';
import notesImage from '../images/notesImage.svg';
import lectureImage from '../images/lectureImage.png';
import useStyles from '../styles/NotesModal';

const NotesModal = (props) => {
  const { setNotesModal, chapter } = props;
  const dispatch = useDispatch();
  const [activeOption, setActiveOption] = useState('notes');
  const [addNotesForm, setAddNotesForm] = useState(false);
  const [notesFile, setNotesFile] = useState(null);
  const classes = useStyles();

  const handleChange = (e) => {
    let file = e.target.files[0];
    setNotesFile(file);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(activeOption === 'notes' ? 'notes' : 'video', notesFile);
    if (notesFile) {
      activeOption === 'notes'
        ? dispatch(uploadNotes(formData, chapter.id))
        : dispatch(uploadLecture(formData, chapter.id))
    }
    setAddNotesForm(false);
    setNotesFile(null);
  };

  const handleDelete = () => {
  };

  return (
    <div className={classes.NotesModal}>
      <div className={classes.NotesModalContent}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setNotesModal(false)}
        />
        <div className={classes.NotesModalContainer}>
          <div className={classes.NotesModaloptionsDiv}>
            <p onClick={() => setActiveOption('notes')}
              className={`${classes.NotesModaloption} ${activeOption === 'notes' && classes.activeOption}`}
            >
              Notes
            </p>
            <p onClick={() => setActiveOption('lectures')}
              className={`${classes.NotesModaloption} ${activeOption === 'lectures' && classes.activeOption}`}
            >
              Lectures
            </p>
          </div>
          <div>
            <Button
              color="secondary"
              className={classes.addModifyButton}
              onClick={() => setAddNotesForm(true)}
            >
              Add/Modify {activeOption === 'notes' ? 'Notes' : 'Lecture'}
            </Button>
            {addNotesForm && (
              <div>
                <form onSubmit={handleUpload} className={classes.modalForm}>
                  {activeOption === 'notes' ?
                    <input
                      type='file'
                      accept='w/pdf'
                      onChange={handleChange}
                    /> :
                    <input
                      type='file'
                      accept='video/mp4'
                      onChange={handleChange}
                    />
                  }
                  <div className={classes.NotesModalButtonDiv}>
                    <Button
                      size="small"
                      type='submit'
                      variant='contained'
                      color='primary'
                    >
                      Upload
                    </Button>
                    <Button
                      size="small"
                      variant='contained'
                      onClick={() => setAddNotesForm(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {((activeOption === 'notes' && chapter.notes) ||
              (activeOption === 'lectures' && chapter.url)) && (
                <div className={classes.NotesModalCard}>
                  <div className={classes.NotesModalCardImageContainer}>
                    <img
                      src={activeOption === 'notes' ? notesImage : lectureImage}
                      alt="notes-img"
                      className={classes.NotesModalCardImage}
                    />
                  </div>
                  <p className={classes.NotesModalCardText}>
                    {chapter.name} {activeOption === 'notes' ? 'notes' : 'lecture'}
                  </p>
                  <div className={classes.NotesModalButtonContainer}>
                    <Button
                      size="small"
                      color="primary"
                      startIcon={<OpenInNewIcon />}
                    >
                      <a
                        href={activeOption === 'notes' ? chapter.notes : chapter.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        OPEN
                      </a>
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesModal;
