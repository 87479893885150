import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-root': {
      padding: '0.5rem'
    }
  },
  EventsPageContainer: {
    width: '90%',
    margin: '4rem auto',
  },
  tableHeaderCell: {
    backgroundColor: '#4059b5',
    color: 'white',
    fontWeight: 600,
  },
  buttonContainer: {
    textAlign: 'right',
    margin: '0.5rem 0',
  },
 
  eventModal: {
    position: 'fixed',
    zIndex: '10',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  eventModalContent: {
    position: 'fixed',
    top: '53%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#fefefe',
    width: '50%',
    maxHeight: '85vh',
    borderRadius: '10px',
    textAlign: 'center',
    overflow: 'auto',
  },
  closeIcon: {
    float: 'right',
    margin: '0.3rem 0.3rem 0 0',
    cursor: 'pointer',
  },
  eventModalTitle: {
    fontWeight: '400',
    fontSize: '1.5rem',
    marginTop: '1.5rem',
  },
  eventModalInput: {
    width: '45%',
    margin: '1rem 0',
    textAlign: 'left'
  },
  eventModalForm: {
    width: '80%',
    margin: '0 auto 2rem auto',
  },
  eventModalButton: {
    marginTop: '2rem',
    width: '100%'
  },
  descText: {
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  descBox: {
    marginBottom: '1.8rem'
  },
  eventImgInput: {
    marginBottom: '1rem'
  },
  workshopImgTextDiv: {
    width: '45%',
    margin: '0 0 0 auto',
  },
  workshopImgText: {
    textAlign: 'left',
    margin: '0.5rem 0 -0.9rem 0',
  },
  testimonialImgText: {
    textAlign: 'left',
    margin: '0.6rem 0 0.2rem 0',
  },
  testimonialInputImage: {
    marginBottom: '2.5rem'
  },
  testimonialReview: {
    marginBottom: '1.5rem'
  },
  imgDiv: {
    width: '45%',
  },
  switchRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  resultIcon: {
    fontSize: '1.3rem',
    cursor: 'pointer',
    color: '#4059b5',
  },
  editIcon: {
    fontSize: '1.3rem',
    cursor: 'pointer',
    color: 'grey',
  },
  deleteIcon: {
    fontSize: '1.3rem',
    cursor: 'pointer',
    color: '#dd404e'
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  meetLinkInput: {
    width: '100%',
    marginTop: '1.3rem'
  },
  createButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',  // Aligns button to the right
    margin: '0.5rem 0', // Adds top and bottom margin
    textTransform: 'uppercase',  // Ensures text is uppercase
    '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center', // Aligns the text and icon
    }
}

  
}));