import { DELETE_LECTURE_PRICE, GET_LECTURE_PRICE } from "../actionTypes"
const Initialstate={
    getLecturePrices:[]
}



export const lectureReducer=(state=Initialstate,action)=>{

    switch(action.type){
            case GET_LECTURE_PRICE:
                return {
                    ...state,
                    getLecturePrices:action?.payload
                }

            case DELETE_LECTURE_PRICE:
                return state

             default:
                return state
    }

}



export default lectureReducer;