import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  Input,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  getAllWorkshops,
  addWorkshop,
  updateWorkshop,
  deleteWorkshop,
} from '../redux/actions/workshopActions';
import useStyles from '../styles/EventsPage';

const initialDetails = {
  topic: '',
  price: '',
  desc: '',
  img: null,
  startDate: '',
  meetLink: '',
};

const WorkshopPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addWorkshopModal, setAddWorkshopModal] = useState(false);
  const [workshopDetails, setWorkshopDetails] = useState(initialDetails);
  const [editableWorkshop, setEditableWorkshop] = useState('');
  const [editWorkshopModal, setEditWorkshopModal] = useState(false);

  const { allWorkshops } = useSelector((store) => store.workshopReducer);

  useEffect(() => {
    if (allWorkshops.length === 0) {
      dispatch(getAllWorkshops());
    }
  }, [dispatch, allWorkshops]);

  const rowCount = allWorkshops.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    dispatch(deleteWorkshop(id));
  };

  const handleDetailsChange = (e) => {
    const { name } = e.target;
    setWorkshopDetails({ ...workshopDetails, [name]: e.target.value });
  };

  const handleEditDetailsChange = (e) => {
    const { name } = e.target;
    setEditableWorkshop({ ...editableWorkshop, [name]: e.target.value });
  };

  const handleDescChange = (value) => {
    setWorkshopDetails({ ...workshopDetails, desc: value });
  };

  const handleEditDescChange = (value) => {
    setEditableWorkshop({ ...editableWorkshop, desc: value });
  };

  const handleImageChange = (e) => {
    let file = e.target.files[0];
    setWorkshopDetails({ ...workshopDetails, img: file });
  };

  const handleEditImageChange = (e) => {
    let file = e.target.files[0];
    setEditableWorkshop({ ...editableWorkshop, img: file });
  };

  const handleEditClick = (workshop) => {
    const workshopObj = { ...workshop };
    workshopObj.startDate = new Date(Date.parse(workshop.start_date)).toISOString().split('T')[0];
    delete workshopObj.start_date;
    workshopObj.meetLink = workshop.workshopLink;
    delete workshopObj.workshopLink;
    setEditableWorkshop(workshopObj);
    setEditWorkshopModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const [yyyy, mm, dd] = workshopDetails.startDate.split('-');
    const formData = new FormData();
    formData.append("topic", workshopDetails.topic);
    formData.append("price", workshopDetails.price);
    formData.append("desc", workshopDetails.desc);
    formData.append("meetLink", workshopDetails.meetLink);
    formData.append("image", workshopDetails.img);
    formData.append("startTime", new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd) + 1));
console.log('formData', formData)
    dispatch(addWorkshop(formData));
    setWorkshopDetails(initialDetails);
    setAddWorkshopModal(false);
    dispatch(getAllWorkshops())
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const [yyyy, mm, dd] = editableWorkshop.startDate.split('-');
    const formData = new FormData();
    formData.append("topic", editableWorkshop.topic);
    formData.append("price", editableWorkshop.price);
    formData.append("desc", editableWorkshop.desc);
    formData.append("meetLink", editableWorkshop.meetLink);
    formData.append("image", editableWorkshop.img);
    formData.append("startTime", new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd) + 1));

    dispatch(updateWorkshop(editableWorkshop.id, formData));
    setEditWorkshopModal(false);
    dispatch(getAllWorkshops())
  };

  return (
    <MiniDrawer>
      <div className={classes.EventsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddWorkshopModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Workshop Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Title
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allWorkshops?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((workshop, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={classes.tableRow}
                      >
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{workshop.topic}</TableCell>
                        <TableCell align='center'>{workshop.price}</TableCell>
                        <TableCell align='center'>
                          {workshop.start_date.replace('6:30 PM', '')}
                        </TableCell>
                        <TableCell align='center'>
                          <EditIcon
                            onClick={() => handleEditClick(workshop)}
                            className={classes.editIcon}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(workshop.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {addWorkshopModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddWorkshopModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Add Workshop</h1>
              <form className={classes.eventModalForm} onSubmit={handleSubmit}>
                <div className={classes.inputRow}>
                  <TextField
                    name="topic"
                    required
                    label="Title"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.topic}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                  <TextField
                    type="number"
                    name="price"
                    required
                    label="Price"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.price}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                </div>
                <p className={classes.descText}>Description</p>
                <ReactQuill
                  value={workshopDetails.desc}
                  onChange={handleDescChange}
                  className={classes.descBox}
                />
                <div className={classes.workshopImgTextDiv}>
                  <p className={classes.workshopImgText}>Workshop Image</p>
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    name="startDate"
                    required
                    label="Start Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.startDate}
                    onChange={handleDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Input
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    className={classes.eventModalInput}
                    onChange={handleImageChange}
                  />
                </div>
                <TextField
                  name="meetLink"
                  required
                  label="Meeting Link"
                  size="small"
                  variant="outlined"
                  value={workshopDetails.meetLink}
                  className={classes.meetLinkInput}
                  onChange={handleDetailsChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
        {editWorkshopModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setEditWorkshopModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Edit Workshop</h1>
              <form className={classes.eventModalForm} onSubmit={handleSaveChanges}>
                <div className={classes.inputRow}>
                  <TextField
                    name="topic"
                    required
                    label="Title"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop.topic}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  <TextField
                    type="number"
                    name="price"
                    required
                    label="Price"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop.price}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                </div>
                <p className={classes.descText}>Description</p>
                <ReactQuill
                  value={editableWorkshop.desc}
                  onChange={handleEditDescChange}
                  className={classes.descBox}
                />
                <div className={classes.workshopImgTextDiv}>
                  <p className={classes.workshopImgText}>Workshop Image</p>
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    name="startDate"
                    required
                    label="Start Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop.startDate}
                    onChange={handleEditDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Input
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    className={classes.eventModalInput}
                    onChange={handleEditImageChange}
                  />
                </div>
                <TextField
                  name="meetLink"
                  required
                  label="Meeting Link"
                  size="small"
                  variant="outlined"
                  value={editableWorkshop.meetLink}
                  className={classes.meetLinkInput}
                  onChange={handleEditDetailsChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default WorkshopPage;