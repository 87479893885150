import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import GetAppIcon from '@material-ui/icons/GetApp';
import useStyles from '../styles/BoardsPage';
import { getAllPurchases } from '../redux/actions/api';
import * as XLSX from 'xlsx';


import ExcelJS from 'exceljs';



import moment from 'moment';


const InvoicePage = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const statePincodeMap = {
    'andaman and nicobar islands': '744101', // Port Blair
    'andhra pradesh': '520001',              // Amaravati
    'arunachal pradesh': '791111',           // Itanagar
    'assam': '781001',                       // Dispur
    'bihar': '800001',                       // Patna
    'chandigarh': '160001',                  // Chandigarh
    'chhattisgarh': '492001',                // Raipur
    'dadra and nagar haveli and daman and diu': '396210', // Daman
    'delhi': '110001',                       // New Delhi
    'goa': '403001',                         // Panaji
    'gujarat': '380001',                     // Gandhinagar
    'haryana': '122001',                     // Chandigarh
    'himachal pradesh': '171001',            // Shimla
    'jammu and kashmir': '180001',           // Jammu
    'jharkhand': '834001',                   // Ranchi
    'karnataka': '560001',                   // Bengaluru
    'kerala': '695001',                      // Thiruvananthapuram
    'ladhak': '194101',                      // Leh
    'lakshadweep': '682555',                 // Kavaratti
    'madhya pradesh': '462001',              // Bhopal
    'maharashtra': '400001',                 // Mumbai
    'manipur': '795001',                     // Imphal
    'meghalaya': '793001',                   // Shillong
    'mizoram': '796001',                     // Aizawl
    'nagaland': '797001',                    // Kohima
    'odisha': '751001',                      // Bhubaneswar
    'punjab': '160001',                      // Chandigarh
    'rajasthan': '302001',                   // Jaipur
    'sikkim': '737101',                      // Gangtok
    'tamil nadu': '600001',                  // Chennai
    'telangana': '500001',                   // Hyderabad
    'tripura': '799001',                     // Agartala
    'uttar pradesh': '201001',               // Lucknow
    'uttarakhand': '248001',                 // Dehradun
    'west bengal': '700001',                 // Kolkata
    'dadar and nagar haveli': '396230',      // Silvassa
    'daman and diu': '396220',                // Daman
    'telangana': '500001'                     // Hyderabad
  };
  
  
  const [purchases, setPurchases] = useState({
    courses: [],
    events: [],
    exams: [],
    project: [],
    chapter: [],
    live: [],
    register:[]
  });

  const invoicePrefixMap = {
    'Course': 'CR',
    'Exam': 'EX',
    'Chapter': 'CH',
    'Project': 'PR',
    'Live': 'LV',
    'Event': 'EV',
    'Register':'RG'
  };

  const generateInvoiceNumber = (purchaseDate, id, dataType, itemId) => {
    if (!purchaseDate) return '';  // Guard clause if purchaseDate is undefined or null
    
    // Ensure purchaseDate is a valid Date object
    const dateObj = new Date(purchaseDate);
    if (isNaN(dateObj)) return '';  // If invalid date, return empty string
    
    const dateString = dateObj.toISOString().split('T')[0].replace(/-/g, ''); // YYYYMMDD
    const timeString = dateObj.toTimeString().split(' ')[0].replace(/:/g, ''); // HHMMSS
    
    return `${dataType}-${id}${dateString}-${timeString}-${itemId}`;
  };
  
  
  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const { data } = await getAllPurchases();
        console.log('data', data)
        setPurchases({
          courses: data?.coursePurchases || [],
          events: data?.eventPurchases || [],
          exams: data?.examPurchases || [],
          project: data?.projectPurchases || [],
          chapter: data?.chapterPurchases || [],
          live: data?.livePurchases || [],
          register:data?.registrationFees || []
        });
      } catch (error) {
        console.error("Error fetching purchases", error);
      }
    };
  
    fetchPurchases();
  }, []);
  
  const allPurchases = [
    ...purchases.courses,
    ...purchases.events,
    ...purchases.exams,
    ...purchases.project,
    ...purchases.chapter,
    ...purchases.live,
    ...purchases.register
  ];
  
  const handleDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Invoice');

    // Define columns with headers
    worksheet.columns = [
        { header: 'Voucher Date', key: 'voucherDate', width: 15 },
        { header: 'Voucher Type Name', key: 'voucherType', width: 20 },
        { header: 'Voucher Number', key: 'voucherNumber', width: 20 },
        { header: 'Party Name', key: 'partyName', width: 30 },
        { header: 'Buyer/Supplier - Bill to/from', key: 'billTo', width: 30 },
        { header: 'Buyer/Supplier - Mailing Name', key: 'mailingName', width: 30 },
        { header: 'Buyer/Supplier - Address', key: 'address', width: 30 },
        { header: 'Buyer/Supplier - State', key: 'state', width: 20 },
        { header: 'Buyer/Supplier - Country', key: 'country', width: 20 },
        { header: 'Buyer/Supplier - GST Registration Type', key: 'gstRegType', width: 40 },
        { header: 'Place of Supply', key: 'placeOfSupply', width: 20 },
        { header: 'Buyer/Supplier - GSTIN/UIN', key: 'gstin', width: 20 },
        { header: 'Buyer/Supplier - Pincode', key: 'pincode', width: 20 },
        { header: 'Ledger Name', key: 'ledgerName', width: 20 },
        { header: 'Ledger Amount', key: 'ledgerAmount', width: 15 },
        { header: 'Ledger Amount Dr/Cr', key: 'ledgerDrCr', width: 15 },
        { header: 'Item Name', key: 'itemName', width: 30 },
        { header: 'Billed Quantity', key: 'billedQuantity', width: 15 },
        { header: 'UOM', key: 'uom', width: 10 },
        { header: 'Item Rate per', key: 'itemRate', width: 15 },
        { header: 'Disc%', key: 'disc', width: 10 },
        { header: 'Item Amount', key: 'itemAmount', width: 15 },
        { header: 'Change Mode', key: 'changeMode', width: 15 },
        { header: 'Disc Amount Dr/Cr', key: 'discAmountDrCr', width: 15 },
        { header: 'IGST AMT', key: 'igstAmt', width: 15 },
        { header: 'IGST DR/CR', key: 'igstDrCr', width: 15 },
        { header: 'CGST AMT', key: 'cgstAmt', width: 15 },
        { header: 'CGST DR/CR', key: 'cgstDrCr', width: 15 },
        { header: 'SGST/UTGST AMT', key: 'sgstAmt', width: 15 },
        { header: 'SGST/UTGST DR/CR', key: 'sgstDrCr', width: 15 },
        { header: 'Party Ledger Dr/Cr', key: 'partyLedgerDrCr', width: 15 }
    ];

    // Add data to the worksheet
    allPurchases.forEach((item, index) => {
        worksheet.addRow({
            voucherDate: moment(item?.purchasedOn).format('DD-MM-YYYY'),
            voucherType: 'Sales Online',
            voucherNumber: `ONL${index + 1}`,
            partyName: `${item?.user?.firstname || ""} ${item?.user?.lastname || ""} (AA000${item?.user?.id}) ` ,//id
            billTo: `${item?.user?.firstname || ""} ${item?.user?.lastname || ""}  (AA000${item?.user?.id})`,//id
            mailingName:  `${item?.user?.firstname || ""} ${item?.user?.lastname || ""}  (AA000${item?.user?.id})`, //id
            address: item?.user?.address,
            state: item?.user?.state,
            country: 'India',
            gstRegType: 'Unregistered/Consumer',
            placeOfSupply: item?.user?.state,  //done
            gstin: "",    //'32AAFCE5000L1Z1', 
            pincode:statePincodeMap[item?.user?.state.toLowerCase()] || "Unknown", // Get pincode from the map
            ledgerName: 'Sales (online)',
            ledgerAmount: item?.paidAmount ? (parseFloat(item.paidAmount) + (parseFloat(item.paidAmount) * 0.18)).toFixed(2) : 0,
            ledgerDrCr: 'cr',
            itemName: getItemName(item),
            billedQuantity: 1,
            uom: 'NOS',
            itemRate: item?.originalAmount,  //is original price
            disc: item.originalAmount && item.paidAmount ? ((item.originalAmount - item.paidAmount) / item.originalAmount * 100).toFixed(2) : '',
            itemAmount: item?.paidAmount,
            changeMode: 'Item Invoice',
            discAmountDrCr: 'Dr',
            igstAmt: item?.user?.state?.toLowerCase() !== 'kerala' ? (item?.paidAmount * 0.18).toFixed(2) : '',
            igstDrCr: 'CR',
            cgstAmt: item?.user?.state?.toLowerCase() === 'kerala' && item?.paidAmount ? (item.paidAmount * 0.09).toFixed(2) : '',
            cgstDrCr: 'CR',
            sgstAmt: item?.user?.state?.toLowerCase() === 'kerala' && item?.paidAmount ? (item.paidAmount * 0.09).toFixed(2) : '',
            sgstDrCr: 'CR',
            partyLedgerDrCr: 'Dr'
        });
    });

    // Style header
    const voucherDateColumnIndex = 1; // ExcelJS uses 1-based index for columns
    const voucherTypeColumnIndex = 2; // ExcelJS uses 1-based index for columns
    worksheet.getRow(1).getCell(voucherDateColumnIndex).font = { bold: true };
    worksheet.getRow(1).getCell(voucherTypeColumnIndex).font = { bold: true };
    worksheet.getRow(1).getCell(14).font = { bold: true };
    worksheet.getRow(1).getCell(15).font = { bold: true };
  
    // worksheet.getRow(1).font = { bold: true, color: { argb: 'FF0000FF' } }; // Blue header
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' } // Yellow background
    };

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Invoice_list.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

// Function to get item name based on type
const getItemName = (item) => {
    switch (item.type) {
        case 'Course':
            return 'Course-' + item?.course?.subject;
        case 'Exam':
            return 'Exam-' + item?.exam?.select_subject;
        case 'Chapter':
            return 'Chapter-' + item?.chapter?.name;
        case 'Project':
            return 'Project-' + item?.project?.project_name;
        case 'Live':
            return 'Live-' + item?.course?.subject;
        case 'Event':
            return 'Event-' + item?.event?.topic;
        case 'Register':
              return 'Register Fees';
        default:
            return '';
    }
};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
console.log('purchases', purchases)
  return (
    <MiniDrawer>
      <div className={classes.BoardsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleDownloadExcel}
          >
            Download Excel
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Standards Table'>
              <TableHead>
                <TableRow>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Sr. No.</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Type</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Item Name</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Buyer</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Order Id</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Payment Id</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Invoice no</TableCell>

                  <TableCell align="center"  className={classes.tableHeaderCell}>Purchase Date</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Original Amount</TableCell>
                  <TableCell align="center"  className={classes.tableHeaderCell}>Paid Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
  {allPurchases
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((purchase, index) => (
      <TableRow hover key={index} tabIndex={-1}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{purchase.type}</TableCell>
        
        {/* Conditionally render based on the type */}
        <TableCell align="center">
  {purchase.type === 'Course' && purchase?.course?.subject}
  {purchase.type === 'Exam' && purchase?.exam?.select_subject }
  {purchase.type === 'Chapter' && purchase?.chapter?.name}
  {purchase.type === 'Project' && purchase?.project?.project_name }
  {purchase.type === 'Live' && purchase?.course?.subject }
  {purchase.type === 'Event' && purchase?.event?.topic}
  {purchase.type === 'Register' &&  "Registration Fees"}
  
</TableCell>


<TableCell align="center">
  {(purchase?.user?.firstname || "") + " " + (purchase?.user?.lastname || "")}
</TableCell>

        <TableCell align="center">{purchase.orderId}</TableCell>
        <TableCell align="center">{purchase.paymentId}</TableCell>

     
          <TableCell align="center">
  {/* {`ONL/${generateInvoiceNumber(purchase.purchasedOn, purchase.userId, invoicePrefixMap[purchase.type],
  
    //  purchase.type === 'Live' ?   purchase.courseId : purchase[purchase.type.toLowerCase()]?.id || 'N/A'
   )}`} */}


{`ONL/${generateInvoiceNumber(
  purchase.purchasedOn,
  purchase.userId,
  invoicePrefixMap[purchase.type],
  (() => {
    switch (purchase.type) {
      case "Live":
        return purchase.courseId;

      case "Register":
        return purchase.id;

      default:
        return purchase[purchase.type.toLowerCase()]?.id || 'N/A';
    }
  })()  // IIFE to return value based on the switch statement
)}`}

</TableCell>
<TableCell align="center">
  {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(purchase.purchasedOn)) || 'NA'}
</TableCell>
        <TableCell align="center">{purchase.originalAmount}</TableCell>
        <TableCell align="center">{purchase.paidAmount}</TableCell>
      </TableRow>
    ))}
</TableBody>


            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={allPurchases.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </MiniDrawer>
  );
};

export default InvoicePage;
