import * as api from './api';
import {
  GET_ALL_TESTIMONIALS,
  ADD_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
  DELETE_TESTIMONIAL
} from '../actionTypes';

export const getAllTestimonials = () => async (dispatch) => {
  try {
    const { data } = await api.getAllTestimonials();
    dispatch({
      type: GET_ALL_TESTIMONIALS,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const addTestimonial = (formData) => async (dispatch) => {
  try {
    await api.addTestimonial(formData);
    dispatch({ type: ADD_TESTIMONIAL });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateTestimonial = (id, formData) => async (dispatch) => {
  try {
    await api.updateTestimonial(id, formData);
    dispatch({ type: UPDATE_TESTIMONIAL });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteTestimonial = (id) => async (dispatch) => {
  try {
    await api.deleteTestimonial(id);
    dispatch({
      type: DELETE_TESTIMONIAL,
      payload: id,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};