import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    marginBottom: '20px',
},
label: {
  fontSize: '1.2rem', 
},
select: {
    borderRadius: '8px',
    backgroundColor: '#f0f4ff',
    '&:focus': {
        borderColor: '#3f51b5',
        backgroundColor: '#ffffff',
    },
    '&:hover': {
        backgroundColor: '#e3f2fd', 
    },
},
menuItem: {
    '&:hover': {
        backgroundColor: '#bbdefb',
    },
},
  root: {
    '& .MuiTableCell-root': {
      padding: '0.2rem', // Default padding for larger screens
      fontSize: '0.75rem', // Default font size for larger screens
      wordBreak: 'break-word', // Ensure text wrapping
      [theme.breakpoints.down('sm')]: { // Apply for tablet and mobile screens
        padding: '0.1rem',
        fontSize: '0.65rem',
      },
    },
  },
  ListPageContainer: {
    width: '100%',
    margin: '4rem auto',
  },

  tableDiv: {
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem', // Reduced top margin for smaller screens
    },
  },

  tableTitle: {
    fontSize: '1rem', // Default font size for larger screens
    textAlign: 'center',
    marginTop: '0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem', // Reduced font size for smaller screens
    },
  },

  tableHeaderCell: {
    backgroundColor: '#4059b5',
    color: 'white',
    fontWeight: 600,
    fontSize: '0.8rem', // Default font size for larger screens
    padding: '0.3rem', // Default padding for larger screens
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
      padding: '0.2rem',
    },
  },

  rowWise: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.2rem', // Default padding for larger screens
    [theme.breakpoints.down('sm')]: {
      padding: '0.1rem', // Reduced padding for smaller screens
    },
  },

  viewIcon: {
    marginRight: '2px', // Default margin for larger screens
    color: '#3F51B5',
    fontSize: '0.7rem', // Default font size for larger screens
    [theme.breakpoints.down('sm')]: {
      marginRight: '1px',
      fontSize: '0.6rem',
    },
  },

  view: {
    textDecoration: 'underline',
    marginLeft: '2px', // Default margin for larger screens
    color: '#3F51B5',
    fontSize: '0.75rem', // Default font size for larger screens
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1px',
      fontSize: '0.65rem',
    },
  },

  buttonContainer: {
    textAlign: 'right',
    margin: '0.2rem 0', // Default margin for larger screens
    [theme.breakpoints.down('sm')]: {
      margin: '0.1rem 0', // Reduced margin for smaller screens
    },
  }
}));
