import { GET_ALL_USERS, GET_USER_PURCHASES } from '../actionTypes';

const initialState = {
  allUsers: [],
  userPurchases: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action?.payload,
      };

    case GET_USER_PURCHASES:
      return {
        ...state,
        userPurchases: action?.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
