import * as api from './api';
import {
  GET_ALL_EVENTS,
  ADD_EVENT,
  DECLARE_RESULT,
  UPDATE_EVENT,
  DELETE_EVENT,
} from '../actionTypes';

export const getAllEvents = () => async (dispatch) => {
  try {
    const { data } = await api.getAllEvents();
    dispatch({
      type: GET_ALL_EVENTS,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const addEvent = (formData) => async (dispatch) => {
  try {
    await api.addEvent(formData);
    dispatch({ type: ADD_EVENT });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateEvent = (id, formData) => async (dispatch) => {
  try {
    await api.updateEvent(id, formData);
    dispatch({ type: UPDATE_EVENT });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    await api.deleteEvent(id);
    dispatch({
      type: DELETE_EVENT,
      payload: id,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const declareResult = (formData, id) => async (dispatch) => {
  try {
    await api.declareResult(formData, id);
    dispatch({ type: DECLARE_RESULT });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};