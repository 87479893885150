import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import moment from 'moment';
import useStyles from '../styles/UsersListPage';

const PurchasedEventsTable = (props) => {
  const classes = useStyles();
  const { events, userId } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowCount = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className={classes.tableDiv}>
      <h1 className={classes.tableTitle}>Purchased Events</h1>
      <Paper className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label='Users Table'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Sr. No.
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Event Name
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Register Type
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Price
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Start Time
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Purchased On
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Invoice Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((event, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      className={classes.tableRow}
                    >
                      <TableCell align='center'>{index + 1}</TableCell>
                      <TableCell align='center'>{event.topic}</TableCell>
                      <TableCell align='center'>
                        {event.UserEvents[event.UserEvents.length - 1].isSolo
                          ? 'Solo'
                          : 'Group'}
                      </TableCell>
                      <TableCell align='center'>
                        {
                          event.UserEvents[event.UserEvents.length - 1]
                            .paidAmount
                        }
                      </TableCell>
                      <TableCell align='center'>
                        {moment(event.start_time).format('MMMM Do YYYY')}
                      </TableCell>
                      <TableCell align='center'>
                        {moment(
                          event.UserEvents[event.UserEvents.length - 1]
                            .purchasedOn
                        ).format('MMMM Do YYYY, hh:mm a')}
                      </TableCell>
                      <TableCell align='center'>{`0000560/${new Date(
                        event.UserEvents[
                          event.UserEvents.length - 1
                        ].purchasedOn
                      )
                        .getDate()
                        .toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}${(
                        new Date(
                          event.UserEvents[
                            event.UserEvents.length - 1
                          ].purchasedOn
                        ).getMonth() + 1
                      ).toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}${new Date(
                        event.UserEvents[
                          event.UserEvents.length - 1
                        ].purchasedOn
                      ).getFullYear()}/${userId}/${event.id}`}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component='div'
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default PurchasedEventsTable;
