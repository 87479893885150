
import { CREATE_PROJECT, DELETE_PROJECT, GET_PROJECT, UPDATE_PROJECT } from "../actionTypes";

const initialState={
projectData:[]
}
export const projectReducer=(state=initialState,action)=>{
    switch(action.type){
        case GET_PROJECT:
            return{
                ...state,
                projectData:action?.payload
            }
         default:
            return state
    }

}


export default projectReducer;