import { GET_ALL_USERS_BY_TEACHER, CREATE_SINGLE_USER_BY_TEACHER, CREATE_BULK_USER, ALL_PENDING_USERS } from '../actionTypes';
import * as api from './api';

// Action to get all pending users
export const getAllPendingUsers = () => async (dispatch) => {
    try {
        const { data } = await api.getAllPendingUsers();
        dispatch({
            type: ALL_PENDING_USERS,
            payload: data
        });
        console.log("API Call pending admin panel:", data);
        return data;
    } catch (err) {
        console.error('Error fetching pending users:', err);
        throw err; // Consider showing a user-friendly message instead
    }
};

// Action to get all users by a teacher
export const getAllUsersByTeacher = () => async (dispatch) => {
    try {
        const { data } = await api.getAllUsersByTeacher();
        dispatch({
            type: GET_ALL_USERS_BY_TEACHER,
            payload: data
        });
        console.log("API Call Successssssss admin panel:", data);
        return data;
    } catch (err) {
        console.error('Error fetching users by teacher:', err);
        throw err; // Consider showing a user-friendly message instead
    }
};

// Action to create a single user by a teacher
export const createSingleUserByTeacher = (formData) => async (dispatch) => {
    try {
        const { data } = await api.createSingleUserByTeacher(formData);
        console.log('User created in admin panel action:', data);
        dispatch({
            type: CREATE_SINGLE_USER_BY_TEACHER,
            payload: data
        });
        dispatch(getAllUsersByTeacher());
        return data; // Optionally return the created user data for further processing
    } catch (error) {
        console.error('Error creating user:', error);
        throw error; // Consider showing a user-friendly message instead
    }
};

// Action to create bulk users
export const createBulkUser = (formData) => async (dispatch) => {
    try {
        const { data } = await api.createBulkUser(formData);
        dispatch({
            type: CREATE_BULK_USER,
            payload: data
        });
        return data; // Optionally return the result for further processing
    } catch (error) {
        console.error('Error creating bulk users:', error);
        throw error; // Consider showing a user-friendly message instead
    }
};
