import {
  GET_ALL_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  DECLARE_RESULT,
  DELETE_EVENT,
} from '../actionTypes';

const initialState = {
  allEvents: [],
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTS:
      return {
        ...state,
        allEvents: action?.payload,
      };

    case DELETE_EVENT:
      return {
        ...state,
        allEvents: state.allEvents.filter(event => event.id !== action.payload)
      };

    case ADD_EVENT:
    case UPDATE_EVENT:
    case DECLARE_RESULT:
      return state;

    default:
      return state;
  }
};

export default eventReducer;
