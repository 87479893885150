import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-root': {
      padding: '0.5rem',
    },
  },
  BoardsPageContainer: {
    width: '100%',
    margin: '4rem auto',
  },
  tableHeaderCell: {
    backgroundColor: '#4059b5',
    color: 'white',
    fontWeight: 600,
  },
  buttonContainer: {
    textAlign: 'right',
    margin: '0.5rem 0',
  },
  boardModal: {
    position: 'fixed',
    zIndex: '10',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  boardModalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#fefefe',
    width: '35%',
    borderRadius: '10px',
    textAlign: 'center',
    height: '85%',
    margin: '1.5rem auto',
    overflow: 'auto',
    '@media (max-width: 768px)': {
      minWidth: '70%',
    },
  },
  closeIcon: {
    float: 'right',
    margin: '0.3rem 0.3rem 0 0',
    cursor: 'pointer',
  },
  boardModalTitle: {
    fontWeight: '400',
    fontSize: '1.5rem',
    marginTop: '1.5rem',
  },
  boardModalInput: {
    width: '100%',
    margin: '1rem 0 1rem 0',
    textAlign: 'left',
  },
  boardModalForm: {
    width: '70%',
    margin: '0 auto',
  },
  boardModalButton: {
    margin: '1rem 0 2.5rem 0',
    width: '100%',
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#dd404e',
  },
  editIcon: {
    cursor: 'pointer',
    color: 'grey',
  },
  appBar: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiIconButton-root': {
        display: 'block', // Ensures the button is visible on smaller screens
      },
      '& .makeStyles-sectionDesktop-26': {
        display: 'none', // Hide any desktop-specific containers
      },
      '& .makeStyles-sectionMobile-3': {
        display: 'flex', // Make sure mobile section is visible
      },
    },
  },
}));
