import * as api from './api';
import { GET_ALL_USERS, GET_USER_PURCHASES } from '../actionTypes';

export const getAllUsers = () => async (dispatch) => {
  try {
    const { data } = await api.getAllUsers();
    // console.log(data);
    dispatch({
      type: GET_ALL_USERS,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};
