import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { login } from '../redux/actions/adminActions';
import useStyles from '../styles/LoginPage';


const initialLoginDetails = { email: '', password: '' };

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [credentials, setCredentials] = useState(initialLoginDetails);

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login(credentials, history)).then(() => {
      setCredentials(initialLoginDetails);
    });
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setCredentials({ ...credentials, [name]: e.target.value });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login to proceed
        </Typography>
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={credentials.email}
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={credentials.password}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <Link to="#" variant="body2">
            Forgot password?
          </Link>
        </form>
      </div>
    </Container>
  );
};

export default LoginPage;
