import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import ChaptersModal from './ChaptersModal';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  getAllExams,
  addExams,
  updateExams,
  deleteExams,
} from '../redux/actions/examsActions';
import useStyles from '../styles/BoardsPage';
import moment from 'moment';

const initialExamsDetails = {
  testType:'',
  examName: '',
  select_subject: '',
  select_chapter: '',
  select_class: '',
  start_time: '',
  end_time: '',
  select_date: '',
  price:0
};


const ExamsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addExamsModal, setAddExamsModal] = useState(false);
  const [updateExamsModal, setUpdateExamsModal] = useState(false);
  const [chaptersModal, setChaptersModal] = useState(-1);
  const [examDetails, setExamsDetails] = useState(initialExamsDetails);
  const [selectedExam, setSelectedExam] = useState(initialExamsDetails);

  const { allExams } = useSelector((store) => store.examsReducer);
  const { allSubjects } = useSelector((store) => store.subjectReducer);
  const { allStandards } = useSelector((store) => store.standardReducer);
  console.log('allExams', allExams)

  useEffect(() => {
    dispatch(getAllExams());
  }, [dispatch]);

  const rowCount = allExams?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDetails = (e) => {
  //   const { name, value } = e.target;
  //   setExamsDetails((prevState) => ({ ...prevState, [name]: value }));
  // };

  const handleEditChanges = (e) => {
    const { name, value } = e.target;
    setSelectedExam((prevExam) => ({ ...prevExam, [name]: value }));
  };

  const handleEditClick = (exam) => {
    setSelectedExam({ ...exam });
    setUpdateExamsModal(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteExams(id));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(addExams(examDetails));
  //   setExamsDetails(initialExamsDetails);
  //   setAddExamsModal(false);
  // };

  const handleSaveChanges = (e) => {

    console.log('selectedExam', selectedExam)

  
    e.preventDefault();
  
    dispatch(updateExams(selectedExam.id,{ price:selectedExam.price})).then(()=>{
      dispatch(getAllExams())
      setUpdateExamsModal(false);
    })
   
  };
  console.log(allExams)
  return (
    <MiniDrawer>
      <div className={classes.BoardsPageContainer}>
        <div className={classes.buttonContainer}>

          {/* <Button
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => setAddExamsModal(true)}
          >
            CREATE
          </Button> */}
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Exams Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>Sr No.</TableCell>
                  <TableCell className={classes.tableHeaderCell}align='center'>Test Type</TableCell>
                  <TableCell className={classes.tableHeaderCell}align='center'>Test Name</TableCell>
                  <TableCell className={classes.tableHeaderCell}align='center'>Subjects</TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>Chapters</TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>Class</TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>Start Time</TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>End Time</TableCell>
                  <TableCell className={classes.tableHeaderCell}align='center'>Date</TableCell>
                  <TableCell className={classes.tableHeaderCell}align='center'>Price</TableCell>
                  <TableCell className={classes.tableHeaderCell}align='center'>Edit</TableCell>
                  <TableCell className={classes.tableHeaderCell}align='center'>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
           

                {Array.isArray(allExams) &&
                  allExams
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((exam, index) => (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{exam?.testType}</TableCell>
                        <TableCell align='center'>{exam?.examName}</TableCell>
                        <TableCell align='center'>{exam?.select_subject}</TableCell>
                        <TableCell align='center'>{exam?.select_chapter}</TableCell>
                        <TableCell align='center'>{exam?.select_class}</TableCell>
                        <TableCell align='center'>{exam?.start_time ? moment(exam?.start_time, "HH.mm.ss").format("hh:mm A") : "Invalid date"}</TableCell>
                        <TableCell align='center'>{exam?.end_time ? moment(exam?.end_time, "HH.mm.ss").format("hh:mm A") : "Invalid date"}</TableCell>
                        <TableCell align='center'>{exam?.select_date}</TableCell>
                        <TableCell align='center'>{exam?.price}</TableCell>
                      
                      
                        <TableCell align='center'>
                          <EditIcon
                            className={classes.editIcon}
                            onClick={() => handleEditClick(exam)}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(exam.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component='div'
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {/* {addExamsModal && (
          <div className={classes.boardModal}>
            <div className={classes.boardModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddExamsModal(false)}
              />
              <h1 className={classes.boardModalTitle}>Add Exam</h1>
              <form className={classes.boardModalForm} onSubmit={handleSubmit}>
                <TextField
                  required
                  name='examName'
                  label='Exam Name'
                  size='small'
                  variant='outlined'
                  value={examDetails.examName}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                />
                <TextField
                  required
                  name='testType'
                  label='Test Type'
                  size='small'
                  variant='outlined'
                  value={examDetails.testType}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                />
                <TextField
                  required
                  name='testName'
                  label='Test Name'
                  size='small'
                  variant='outlined'
                  value={examDetails.testName}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                />
                <TextField
                  required
                  name='subjects'
                  label='Subjects'
                  size='small'
                  variant='outlined'
                  value={examDetails.subjects}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                />
                <TextField
                  required
                  name='chapters'
                  label='Chapters'
                  size='small'
                  variant='outlined'
                  value={examDetails.chapters}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                />
                <TextField
                  required
                  select
                  name='standard'
                  label='Class'
                  variant='outlined'
                  size='small'
                  value={examDetails.standard}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                >
                  {allStandards.map((standard) => (
                    <MenuItem value={standard.class}>
                      {standard.class}th standard
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  startIcon={<SaveIcon />}
                  className={classes.boardModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )} */}
        {updateExamsModal && (
          <div className={classes.boardModal}>
            <div className={classes.boardModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setUpdateExamsModal(false)}
              />
              <h1 className={classes.boardModalTitle}>Edit Exam</h1>
              <form className={classes.boardModalForm} onSubmit={handleSaveChanges}>
                <TextField
                disabled
                  required
                  name='examName'
                  label='Exam Name'
                  size='small'
                  variant='outlined'
                  value={selectedExam.examName}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                />
                <TextField
                disabled
                  required
                  name='testType'
                  label='Test Type'
                  size='small'
                  variant='outlined'
                  value={selectedExam.testType}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                />
                <TextField
                disabled
                  required
                  name='examName'
                  label='Test Name'
                  size='small'
                  variant='outlined'
                  value={selectedExam.examName}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                />
                <TextField
                disabled
                  required
                  name='select_subject'
                  label='Subjects'
                  size='small'
                  variant='outlined'
                  value={selectedExam?.select_subject}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                />
                <TextField
                disabled
                  required
                  name='select_chapter'
                  label='Chapters'
                  size='small'
                  variant='outlined'
                  value={selectedExam?.select_chapter}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                />
                <TextField
                  disabled
                  required
                  select
                  name='select_class'
                  label='Class'
                  variant='outlined'
                  size='small'
                  value={selectedExam?.select_class}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                >
                  {allStandards.map((standard) => (
                    <MenuItem value={standard.class}>
                      {standard.class}th standard
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  required
                  type='numbar'
                  name='price'
                  label='Price'
                  size='small'
                  variant='outlined'
                  value={selectedExam?.price}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  startIcon={<SaveIcon />}
                  className={classes.boardModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default ExamsPage;
