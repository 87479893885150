// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import jwt_decode from 'jwt-decode';
// import Cookies from 'js-cookie';
// import App, { history } from './App';
// import store from './redux/store';
// import './index.css';

// import { setAdminDetails, logout } from './redux/actions/adminActions';

// const jsx = (
//   <Provider store={store}>
//     <App />
//   </Provider>
// );

// if (Cookies.get('adminJWT')) {
//   const decoded = jwt_decode(Cookies.get('adminJWT'));
//   // Check for expired token
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     store.dispatch(logout())
//       .then(() => ReactDOM.render(jsx, document.getElementById('root')));
//   } else {
//     store.dispatch(setAdminDetails(history))
//       .then(() => ReactDOM.render(jsx, document.getElementById('root')));
//   }
// } else {
//   ReactDOM.render(jsx, document.getElementById('root'));
// }

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import App from './App';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import { setAdminDetails, logout } from './redux/actions/adminActions';
import { BrowserRouter as Router } from 'react-router-dom';

const jsx = (
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

const checkAdminJWT = async () => {
  if (Cookies.get('adminJWT')) {
    const decoded = jwt_decode(Cookies.get('adminJWT'));
    const currentTime = Date.now() / 1000;
    
    if (decoded.exp < currentTime) {
      await store.dispatch(logout());
    } else {
      await store.dispatch(setAdminDetails());
    }
  }
  
  root.render(jsx);
};

checkAdminJWT();
