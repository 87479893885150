import {
  GET_ALL_WORKSHOPS,
  ADD_WORKSHOP,
  UPDATE_WORKSHOP,
  DELETE_WORKSHOP
} from '../actionTypes';

const initialState = {
  allWorkshops: [],
};

const workshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WORKSHOPS:
      return {
        ...state,
        allWorkshops: action?.payload,
      };

    case DELETE_WORKSHOP:
      return {
        ...state,
        allWorkshops: state.allWorkshops.filter(workshop => workshop.id !== action.payload)
      };

    case ADD_WORKSHOP:
    case UPDATE_WORKSHOP:
      return state;

    default:
      return state;
  }
};

export default workshopReducer;
