import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MiniDrawer from './MiniDrawer';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import useStyles from '../styles/UsersListPage';
import { getUserPurchases } from '../redux/actions/api';
import { getAllUsers } from '../redux/actions/userActions';
import UserPurchases from './UserPurchases';

const UsersListPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [boolVal, setBoolVal] = useState(false);
  const [showPurchases, setShowPurchases] = useState(false);
  const [clickedUser, setClickedUser] = useState('');
  const [purchases, setPurchases] = useState({
    courses: [],
    events: [],
    workshops: [],
    chapters:[],
    projects:[],
    live:[],
    exams:[]
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { allUsers } = useSelector((store) => store.userReducer);

  const rowCount = allUsers?.length;

  useEffect(() => {
    if (!boolVal) {
      dispatch(getAllUsers());
      setBoolVal();
    }
  }, [boolVal, dispatch]);

  const handleshowPurchases = async (id) => {
    const { data } = await getUserPurchases(id);
    console.log(data);

    
    setClickedUser(id);
    setPurchases({
      ...purchases,
      events: data.events,
      workshops: data.workshops,
      chapters:data.chapters,
      courses: data.workshops,
      live:data.live,
      projects:data.projects,
      exams:data.exams
    });
    setShowPurchases(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log(allUsers);

  return (
    <MiniDrawer>
      {!showPurchases ? (
        <div className={classes.ListPageContainer}>
          {/**<div className={classes.buttonContainer}>
            <Button color='primary' startIcon={<ArrowBackIosIcon />}>
              Back
            </Button>
          </div> */}
          <Paper className={classes.root}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label='Users Table'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Sr. No.
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Name
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Email Address
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Phone No.
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Address
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Institution Type
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Institution Name
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Standard
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderCell}
                      align='center'
                    >
                      Purchases
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUsers
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((user, index) => {
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell align='center'>{index + 1}</TableCell>
                          <TableCell align='center'>{`${user.firstname} ${user.lastname}`}</TableCell>
                          <TableCell align='center'>{user.email}</TableCell>
                          <TableCell align='center'>{user.phone}</TableCell>
                          <TableCell align='center'>
                            {user.address
                              ? `${user.address}${user.state ? ', ' : ''}${
                                  user.state ? user.state : ''
                                }`
                              : 'NA'}
                          </TableCell>
                          <TableCell align='center'>
                            {user.institutionType ? user.institutionType : 'NA'}
                          </TableCell>
                          <TableCell align='center'>
                            {user.institutionType === 'College'
                              ? user.college
                              : user.school}
                          </TableCell>
                          <TableCell align='center'>
                            {user.standard > 0 ? user.standard : ''}
                          </TableCell>
                          <TableCell align='center'>
                            <div
                              className={classes.rowWise}
                              onClick={() => handleshowPurchases(user.id)}
                            >
                              <VisibilityIcon className={classes.viewIcon} />
                              <span className={classes.view}>View</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component='div'
              count={rowCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      ) : (
        <UserPurchases
          setShowPurchases={setShowPurchases}
          userId={clickedUser}
          purchases={purchases}
        />
      )}
    </MiniDrawer>
  );
};

export default UsersListPage;
