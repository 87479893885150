import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import NotesModal from './NotesModal';
import { getAllSubjects } from '../redux/actions/subjectActions';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from '../styles/ChaptersPage';

const ChaptersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const subject = location.state;
  const [notesModal, setNotesModal] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { allSubjects,allChapters } = useSelector((store) => store.subjectReducer);

  useEffect(() => {
    if (allSubjects.length === 0) {
      dispatch(getAllSubjects());
    }
  }, [dispatch, allSubjects]);
  
console.log('allChapters', allChapters)



  const rowCount = subject.chapters.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MiniDrawer>
      <div className={classes.ChaptersPageContainer}>
        <h1 className={classes.ChaptersPageTitle}>{subject.subject}</h1>
        <p>Chapters</p>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Chapter Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Chapter Number
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Chapter Name
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Monthly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Quarterly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Yearly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Edit
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subject?.chapters.sort(
                  (a, b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0)
                ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((chapter, index) => (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      className={classes.tableRow}
                    >
                      <TableCell align="center">{chapter.number}</TableCell>
                      <TableCell align="center">{chapter.name}</TableCell>
                      <TableCell align="center">{chapter?.ch_monthly_price}</TableCell>
                      <TableCell align="center">{chapter?.ch_quarterly_price}</TableCell>
                      <TableCell align="center">{chapter?.ch_yearly_price}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={() => setNotesModal(index)}
                          style={{ padding: '0.5rem' }}
                        >
                          <EditIcon className={classes.editIcon} />
                        </IconButton>
                        {notesModal === index &&
                          <NotesModal
                            setNotesModal={setNotesModal}
                            chapter={chapter}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </MiniDrawer>
  );
};

export default ChaptersPage;
