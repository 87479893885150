import * as api from './api';
import { GET_ALL_BLOGS, ADD_BLOG, UPDATE_BLOG, DELETE_BLOG } from '../actionTypes';

export const getAllBlogs = () => async (dispatch) => {
  try {
    const { data } = await api.getAllBlogs();
    dispatch({
      type: GET_ALL_BLOGS,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const addBlog = (formData) => async (dispatch) => {
  try {
   const {data}= await api.addBlog(formData);
   console.log(data)
    dispatch({ type: ADD_BLOG,payload:data });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateBlog = (id, formData) => async (dispatch) => {
  try {
    await api.updateBlog(id, formData);
    dispatch({ type: UPDATE_BLOG });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  try {
    await api.deleteBlog(id);
    dispatch({
      type: DELETE_BLOG,
      payload: id,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};