import {
  GET_LECTURE_PRICE,
  ADD_LECTURE_PRICES,
  UPDATE_LECTURE_PRICES,
  DELETE_LECTURE_PRICE
} from "../actionTypes";
import * as api from "../actions/api/index";

export const getLecturePrice = () => async (dispatch) => {
  try {
    const { data } = await api.getLecturePrice();

    console.log("data from action", data);
    dispatch({
      type: GET_LECTURE_PRICE,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : "Something went wrong in getting lectures prices";
    console.log(message);
  }
};

export const addLecturePrices = (formData) => async (dispatch) => {
  try {
    const { data } = await api.addLecturePrices(formData);
    dispatch({ type: ADD_LECTURE_PRICES, payload: data });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : "Something went wrong";
    console.log(message);
  }
};

export const updateLecturePrice = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.updateLecturePrices(id, formData);

    dispatch({
      type: UPDATE_LECTURE_PRICES,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : "Something went wrong";
    console.log(message);
  }
};


export const deleteLecturePrice=(id)=>async(dispatch)=>{
  console.log('first', id)
try {
  const {data}=await api.deleteLecturePrice(id);
  dispatch({
    type:DELETE_LECTURE_PRICE,
    payload:data
  })
} catch (err) {

  const message = err?.response?.data?.message
      ? err.response.data.message
      : "Something went wrong in getting lectures prices";
    console.log(message);
  
}
}
