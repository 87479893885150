import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import ChaptersModal from './ChaptersModal';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  getAllSubjects,
  addSubject,
  updateSubject,
  deleteSubject,
} from '../redux/actions/subjectActions';
import useStyles from '../styles/BoardsPage';

const initialSubjectDetails = {
  subject: '',
  class: '',
  board: '',
  yearlyPrice: '',
  halfyearlyPrice: '',
  quarterlyPrice: '',
  monthlyPrice:'',
  desc: '',
};

const SubjectsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addSubjectModal, setAddSubjectModal] = useState(false);
  const [updateSubjectModal, setUpdateSubjectModal] = useState(false);
  const [chaptersModal, setChaptersModal] = useState(-1);
  const [subjectDetails, setSubjectDetails] = useState(initialSubjectDetails);
  const [selectedSubject, setSelectedSubject] = useState('');

  const { allSubjects } = useSelector((store) => store.subjectReducer);
  const { allStandards } = useSelector((store) => store.standardReducer);
  const { allBoards } = useSelector((store) => store.boardReducer);
  
  // useEffect(() => {
  //   if (allSubjects.length === 0) {
  //     dispatch(getAllSubjects());
  //   }
  // }, [dispatch, allSubjects]);
  




  const rowCount =  allSubjects?.length
 

console.log(rowCount)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDetails = (e) => {
    const { name } = e.target;
    // setSubjectDetails({ ...subjectDetails, [name]: e.target.value });
    setSubjectDetails((prevState) => ({ ...prevState, [name]: e.target.value }));

  };

  const handleEditChanges = (e) => {
    const { name } = e.target;
    // setSelectedSubject({ ...selectedSubject, [name]: e.target.value });
    setSelectedSubject((prevSubject) => ({ ...prevSubject, [name]: e.target.value }));

  };


  const handleEditClick = (subject) => {
    setSelectedSubject({ ...subject });
    setUpdateSubjectModal(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteSubject(id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addSubject(subjectDetails)).then(()=>{
     
    setSubjectDetails(initialSubjectDetails);
    setAddSubjectModal(false);}
    )
    
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    dispatch(updateSubject(selectedSubject.id, selectedSubject)).then(()=>{
      dispatch(getAllSubjects())
      setUpdateSubjectModal(false);
    })
  
  };

console.log(allSubjects)
  return (
    <MiniDrawer>
      <div className={classes.BoardsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => setAddSubjectModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Subjects Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Subject Name
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Standard
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Board
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Yearly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Quarterly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Monthly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Chapters
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

{Array.isArray(allSubjects) && allSubjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  .map((sub, index) => {
    return (
      <TableRow
        hover
        key={index}
        tabIndex={-1}
        className={classes.tableRow}
      >
        <TableCell align='center'>{sub.subject}</TableCell>
        <TableCell align='center'>
          {sub.class}th standard
        </TableCell>
        <TableCell align='center'>{sub.board}</TableCell>
        <TableCell align='center'>{sub.yearlyPrice}</TableCell>
        <TableCell align='center'>
          {sub.quarterlyPrice}
        </TableCell>
        <TableCell align='center'>
          {sub.monthlyPrice}
        </TableCell>
        <TableCell align='center'>
          <Button
            color='primary'
            startIcon={<VisibilityIcon />}
            onClick={() => setChaptersModal(index)}
          >
            VIEW
          </Button>
          {chaptersModal === index && (
            <ChaptersModal
              setChaptersModal={setChaptersModal}
              subject={sub}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          <EditIcon
            className={classes.editIcon}
            onClick={() => handleEditClick(sub)}
          />
        </TableCell>
        <TableCell align='center'>
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={() => handleDelete(sub.id)}
          />
        </TableCell>
      </TableRow>
    );
  })
}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component='div'
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {addSubjectModal && (
          <div className={classes.boardModal}>
            <div className={classes.boardModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddSubjectModal(false)}
              />
              <h1 className={classes.boardModalTitle}>Add Subject</h1>
              <form className={classes.boardModalForm} onSubmit={handleSubmit}>
                <TextField
                  required
                  name='subject'
                  label='Subject name'
                  size='small'
                  variant='outlined'
                  value={subjectDetails.subject}
                  className={classes.boardModalInput}
                  onChange={handleChangeDetails}
                />
                <TextField
                  name='desc'
                  label='Subject description'
                  size='small'
                  variant='outlined'
                  value={subjectDetails.desc}
                  className={classes.boardModalInput}
                  onChange={handleChangeDetails}
                />
                <TextField
                  required
                  select
                  name='class'
                  label='Standard'
                  variant='outlined'
                  size='small'
                  value={subjectDetails.class}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                >
                  {allStandards.map((standard) => (
                    <MenuItem value={standard.class}>
                      {standard.class}th standard
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  select
                  name='board'
                  label='Board'
                  variant='outlined'
                  size='small'
                  value={subjectDetails.board}
                  onChange={handleChangeDetails}
                  className={classes.boardModalInput}
                >
                  {allBoards.map((board) => (
                    <MenuItem value={board.name}>{board.name}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  name='yearlyPrice'
                  type='number'
                  label='Yearly price'
                  size='small'
                  variant='outlined'
                  value={subjectDetails.yearlyPrice}
                  className={classes.boardModalInput}
                  onChange={handleChangeDetails}
                />
               
                <TextField
                  required
                  name='quarterlyPrice'
                  type='number'
                  label='Quarterly price'
                  size='small'
                  variant='outlined'
                  value={subjectDetails.quarterlyPrice}
                  className={classes.boardModalInput}
                  onChange={handleChangeDetails}
                />
                <TextField
                  required
                  name='monthlyPrice'
                  type='number'
                  label='Monthly price'
                  size='small'
                  variant='outlined'
                  value={subjectDetails.monthlyPrice}
                  className={classes.boardModalInput}
                  onChange={handleChangeDetails}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  startIcon={<SaveIcon />}
                  className={classes.boardModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
        {updateSubjectModal && (
          <div className={classes.boardModal}>
            <div className={classes.boardModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setUpdateSubjectModal(false)}
              />
              <h1 className={classes.boardModalTitle}>Edit Subject</h1>
              <form
                className={classes.boardModalForm}
                onSubmit={handleSaveChanges}
              >
                <TextField
                  required
                  name='subject'
                  label='Subject name'
                  size='small'
                  variant='outlined'
                  value={selectedSubject.subject}
                  className={classes.boardModalInput}
                  onChange={handleEditChanges}
                />
                <TextField
                  name='desc'
                  label='Subject description'
                  size='small'
                  variant='outlined'
                  value={selectedSubject.desc}
                  className={classes.boardModalInput}
                  onChange={handleEditChanges}
                />
                <TextField
                  required
                  select
                  name='class'
                  label='Standard'
                  variant='outlined'
                  size='small'
                  value={selectedSubject.class}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                >
                  {allStandards.map((standard) => (
                    <MenuItem value={standard.class}>
                      {standard.class}th standard
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  select
                  name='board'
                  label='Board'
                  variant='outlined'
                  size='small'
                  value={selectedSubject.board}
                  onChange={handleEditChanges}
                  className={classes.boardModalInput}
                >
                  {allBoards.map((board) => (
                    <MenuItem value={board.name}>{board.name}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  required
                  name='yearlyPrice'
                  type='number'
                  label='Yearly price'
                  size='small'
                  variant='outlined'
                  value={selectedSubject.yearlyPrice}
                  className={classes.boardModalInput}
                  onChange={handleEditChanges}
                />
              
                <TextField
                  required
                  name='quarterlyPrice'
                  type='number'
                  label='Quarterly price'
                  size='small'
                  variant='outlined'
                  value={selectedSubject.quarterlyPrice}
                  className={classes.boardModalInput}
                  onChange={handleEditChanges}
                />

                  <TextField
                  required
                  name='monthlyPrice'
                  type='number'
                  label='Monthly price'
                  size='small'
                  variant='outlined'
                  value={selectedSubject.monthlyPrice}
                  className={classes.boardModalInput}
                  onChange={handleEditChanges}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  startIcon={<SaveIcon />}
                  className={classes.boardModalButton}
                >
                  Save Changes
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default SubjectsPage;
