import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { addStandard, deleteStandard, getAllStandards } from '../redux/actions/standardActions';
import useStyles from '../styles/BoardsPage';

const StandardsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addStandardModal, setAddStandardModal] = useState(false);
  const [standardName, setStandardName] = useState('');
  const[registrationFees,setRegistrationFees]=useState('')

  const { allStandards } = useSelector((store) => store.standardReducer);

  const rowCount = allStandards.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStandardChange = (e) => {
    setStandardName(e.target.value);
  };
  
  const handleFeesChange = (e) => {
    setRegistrationFees(e.target.value);
  }
  const handleDelete = (id) => {
    dispatch(deleteStandard(id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addStandard(standardName, registrationFees)).then(()=>{
      setStandardName('');
      setRegistrationFees('')
      setAddStandardModal(false)
      dispatch(getAllStandards())
    })
  
  };
  console.log(allStandards)
  
  return (
   
    <MiniDrawer>
      <div className={classes.BoardsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddStandardModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Standards Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Standard Name
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Registration Fees
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allStandards?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((standard, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={classes.tableRow}
                      >
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{standard.class}th standard</TableCell>
                        <TableCell align='center'>{standard.registrationFees}</TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(standard.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {addStandardModal && (
          <div className={classes.boardModal}>
            <div className={classes.boardModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddStandardModal(false)}
              />
              <h1 className={classes.boardModalTitle}>Add Standard</h1>
              <form className={classes.boardModalForm} onSubmit={handleSubmit}>
                <TextField
                  required
                  type="number"
                  label="Standard"
                  size="small"
                  variant="outlined"
                  value={standardName}
                  className={classes.boardModalInput}
                  onChange={handleStandardChange}
                />
                  <TextField
                  required
                  type="number"
                  label="Registration Fees"
                  size="small"
                  variant="outlined"
                  value={registrationFees}
                  className={classes.boardModalInput}
                  onChange={handleFeesChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.boardModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default StandardsPage;
