import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  CssBaseline,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SubjectIcon from '@material-ui/icons/Subject';
import NoteIcon from '@material-ui/icons/Note';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ClassIcon from '@material-ui/icons/Class';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import EventIcon from '@material-ui/icons/Event';
import DescriptionIcon from '@material-ui/icons/Description';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { logout } from '../redux/actions/adminActions';
import { getAllBoards } from '../redux/actions/boardActions';
import { getAllStandards } from '../redux/actions/standardActions';
import { getAllSubjects } from '../redux/actions/subjectActions';
import {getAllExams} from '../redux/actions/examsActions'
import useStyles from '../styles/MiniDrawer';
import { MdOutlinePriceChange } from "react-icons/md";
import { PiChalkboardTeacherBold } from "react-icons/pi";
import { Receipt } from '@material-ui/icons';
import { ReceiptLong } from '@mui/icons-material';


const MiniDrawer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useNavigate();

  const { allBoards } = useSelector((store) => store.boardReducer);
  const { allStandards } = useSelector((store) => store.standardReducer);
  const { allSubjects } = useSelector((store) => store.subjectReducer);

  useEffect(() => {
    if (allBoards.length === 0) {
      dispatch(getAllBoards());
    }
    if (allStandards.length === 0) {
      dispatch(getAllStandards());
    }
    if (allSubjects.length === 0) {
      dispatch(getAllSubjects());
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [openStandardList, setOpenStandardList] = useState(false);
  const [activeStandardList, setActiveStandardList] = useState([]);
  const [activeBoardList, setActiveBoardList] = useState([]);
  const [openAccount, setOpenAccount] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = () => {
    dispatch(logout(history));
    handleAccountClose();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAccountOpen = (event) => {
    setOpenAccount(true);
    setAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setOpenAccount(false);
    setAnchorEl(null);
  };

  const handleStandardClick = (index) => {
    const i = activeStandardList.indexOf(index);
    var newList = [];
    if (i === -1) {
      newList = [...activeStandardList];
      newList.push(index);
    } else {
      newList = [...activeStandardList];
      newList.splice(i, 1);
    }
    setActiveStandardList(newList);
  };

  const handleBoardClick = (str) => {
    const i = activeBoardList.indexOf(str);
    var newList = [];
    if (i === -1) {
      newList = [...activeBoardList];
      newList.push(str);
    } else {
      newList = [...activeBoardList];
      newList.splice(i, 1);
    }
    setActiveBoardList(newList);
  };

  const handleSubmit = (subject) => {
    history('/chapters',{state: subject}
      
    );
  };

  // const filteredSubjects = (standard, board) => {
  //   return allSubjects?.filter(
  //     (subject) =>
  //       subject.class === standard.toString() && subject.board === board
  //   );
  // };

  // console.log(allSubjects)

  const filteredSubjects = (standard, board) => {
    return Array.isArray(allSubjects) ? (
      allSubjects.filter(
        (subject) =>
          subject.class === standard.toString() && subject.board === board
      )
    ) : (
      []
    );
  };
  


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={`${classes.appBar} ${open && classes.appBarShift}`}
      >
        <Toolbar variant='dense'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={`${classes.menuButton} ${open && classes.hide}`}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            Enrouting Careers
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge='end'
              color='inherit'
              className={classes.accountCircle}
              onClick={handleAccountOpen}
            >
              <PowerSettingsNewIcon />
            </IconButton>
            <Menu
              className={classes.menu}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={openAccount}
              onClose={handleAccountClose}
            >
              <MenuItem onClick={handleLogout} className={classes.menuItem}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='persistent'
        className={classes.drawer}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <h1 className={classes.drawerTitle}>Hi Admin!</h1>
          <IconButton className={classes.leftIcon} onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <NavLink to='/boards' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <HomeWorkIcon />
              </ListItemIcon>
              <ListItemText primary='Boards' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/subjects' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <SubjectIcon />
              </ListItemIcon>
              <ListItemText primary='Subjects' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <List className={classes.listItem}>
          <ListItem
            button
            className={classes.listItemButton}
            onClick={() => setOpenStandardList(!openStandardList)}
          >
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary='Notes' />
            {openStandardList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openStandardList} timeout='auto' unmountOnExit>
            {allStandards?.map((standard, index) => (
              <List component='div' disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => handleStandardClick(index)}
                >
                  <ListItemIcon>
                    {activeStandardList.includes(index) ? (
                      <ExpandMore />
                    ) : (
                      <ArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <span className={classes.submenuText}>
                    {standard.class}th standard
                  </span>
                </ListItem>
                <Collapse
                  in={activeStandardList.includes(index)}
                  timeout='auto'
                  unmountOnExit
                >
                  {allBoards?.map((board, index2) => (
                    <List component='div' disablePadding>
                      <ListItem
                        button
                        className={classes.doubleNested}
                        onClick={() => handleBoardClick(`${index}-${index2}`)}
                      >
                        <ListItemIcon>
                          {activeBoardList.includes(`${index}-${index2}`) ? (
                            <ExpandMore />
                          ) : (
                            <ArrowRightIcon />
                          )}
                        </ListItemIcon>
                        <span className={classes.submenuText}>
                          {board.name}
                        </span>
                      </ListItem>
                      <Collapse
                        in={activeBoardList.includes(`${index}-${index2}`)}
                      >
                        {filteredSubjects(standard.class, board.name)?.map(
                          (subject, index3) => (
                            <List component='div' disablePadding>
                              <ListItem
                                button
                                className={classes.tripleNested}
                                onClick={() => handleSubmit(subject)}
                              >
                                <ListItemIcon>
                                  <ArrowRightIcon />
                                </ListItemIcon>
                                <span className={classes.submenuText}>
                                  {subject.subject}
                                </span>
                              </ListItem>
                            </List>
                          )
                        )}
                      </Collapse>
                    </List>
                  ))}
                </Collapse>
              </List>
            ))}
          </Collapse>
        </List>
        <Divider />
        <NavLink to='/standards' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <ClassIcon />
              </ListItemIcon>
              <ListItemText primary='Standards' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/events' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary='Events' />
            </ListItem>
          </List>
          </NavLink>
        <Divider />
          <NavLink to='/exams' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
            <DescriptionIcon/>
              </ListItemIcon>
              <ListItemText primary='Exams' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/invoice' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
          <ReceiptLong/>
               
              </ListItemIcon>
              <ListItemText primary='Invoice' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/testimonials' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <RateReviewIcon />
              </ListItemIcon>
              <ListItemText primary='Testimonials' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/blogs' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary='Blogs' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/projects' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary='Projects' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />



        <NavLink to='/users-list' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary='Users List' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/lecture-requests' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
             
                <MdOutlinePriceChange size={16} />
              </ListItemIcon>
              <ListItemText primary='Online Lecture Prices' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/bulk-list' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
              <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary='Bulk List' />
            </ListItem>
          </List>
        </NavLink>
        <Divider />
        <NavLink to='/teachers' exact className={classes.navLink}>
          <List className={classes.listItem}>
            <ListItem button className={classes.listItemButton}>
              <ListItemIcon>
              
                <PiChalkboardTeacherBold size={16}/>
              </ListItemIcon>
              <ListItemText primary='Teachers List' />
            </ListItem>
          </List>
        </NavLink>
      </Drawer>
     
      <main className={`${classes.content} ${open && classes.contentShift}`}>
        {props.children}
      </main>
    </div>
  );
};

export default MiniDrawer;
