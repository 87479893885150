import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  DialogTitle,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux"; // Import useDispatch to dispatch actions
import { createSingleUserByTeacher, getAllUsersByTeacher } from "../redux/actions/BulkListAction"; // Import the Redux action

const CreateUserModal = ({ open, onClose, formData, isEdit }) => {
  const dispatch = useDispatch(); // Initialize the dispatch
  const initalState={
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    standard: "",
    section: "",
    institutionType: "School",
    school: "",
    college: "",
  }
  const [formState, setFormState] = useState(initalState);

  // Populate form with existing data if in edit mode
  useEffect(() => {
    if (isEdit && formData) {
      // Populate form with existing data if in edit mode
      setFormState((prevState) => ({
        ...prevState,
        ...formData, // Spread the existing user data
      }));
    } else {
      setFormState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        phone: "",
        standard: "",
        section: "",
        institutionType: "School",
        school: "",
        college: "",
      });
    }
  }, [formData, isEdit]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isEdit) {
        // Update existing user logic can be added here if necessary
        // For now, it just creates a new user
        await dispatch(createSingleUserByTeacher(formState));
      } else {
        await dispatch(createSingleUserByTeacher(formState));
      }
      toast.success(isEdit ? "User updated successfully!" : "User created successfully!");
      dispatch(getAllUsersByTeacher()).then(()=>{
        setFormState(initalState)
        onClose(); // Close modal
      })// Refresh the user list
    
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? "Edit User" : "Create User"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstname"
                value={formState.firstname}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastname"
                value={formState.lastname}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formState.email}
                onChange={handleChange}
                required
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                value={formState.password}
                onChange={handleChange}
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                value={formState.phone}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Standard"
                name="standard"
                value={formState.standard}
                onChange={handleChange}
                required
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
                
                {/* Add more options as needed */}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Section"
                name="section"
                value={formState.section}
                onChange={handleChange}
                required
              >

                <MenuItem value="A">A</MenuItem>
                <MenuItem value="B">B</MenuItem>
                <MenuItem value="C">C</MenuItem>
                <MenuItem value="D">D</MenuItem>
                <MenuItem value="E">E</MenuItem>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="H">H</MenuItem>
               


              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Institution Type"
                name="institutionType"
                value={formState.institutionType}
                onChange={handleChange}
              >
                <MenuItem value="School">School</MenuItem>
                <MenuItem value="College">College</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
  {formState.institutionType === "School" ? (
    <TextField
      fullWidth
      label="School"
      name="school"
      value={formState.school}
      onChange={handleChange}
    />
  ) : (
    <TextField
      fullWidth
      label="College"
      name="college"
      value={formState.college}
      onChange={handleChange}
    />
  )}
</Grid>

            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                label="School"
                name="school"
                value={formState.school}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="College"
                name="college"
                value={formState.college}
                onChange={handleChange}
              />
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{isEdit ? "Update" : "Create"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserModal;
