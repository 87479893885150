import * as api from './api';
import { LOGIN, SET_ADMIN_DETAILS, LOGOUT } from '../actionTypes';

export const login = (loginData, history) => async (dispatch) => {
  try {

    const { data } = await api.login(loginData);
console.log('Login Data:', data); // Log the response

    dispatch({
      type: LOGIN,
      payload: { adminDetails: data.result, token: data.accessToken },
    });
    history('/boards');
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
    console.log('Error:', err.response ? err.response.data : err.message);

  }
};

export const logout = (history) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    });
    history('/');
  } catch (err) {
    console.log('something went wrong');
  }
};

export const setAdminDetails = (history) => async (dispatch) => {
  try {
    const admin = await api.getAdmin();
    dispatch({
      type: SET_ADMIN_DETAILS,
      payload: admin.data,
    });
    if (window.location.pathname === '/') {
      history('/boards');
    
    }
    
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

