import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import useStyles from '../styles/UsersListPage';

const PurchasedCoursesTable = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowCount = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className={classes.tableDiv}>
      <h1 className={classes.tableTitle}>Purchased Courses</h1>
      <Paper className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label='Users Table'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Sr. No.
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Name
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Email Address
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Phone No.
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Address
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Institution Type
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Institution Name
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Standard
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>
                  Purchases
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component='div'
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default PurchasedCoursesTable;
