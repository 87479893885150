import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  Input,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import { DropzoneArea } from 'material-ui-dropzone';
import 'react-quill/dist/quill.snow.css';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';import {
  getAllEvents,
  addEvent,
  updateEvent,
  deleteEvent,
  declareResult,
} from '../redux/actions/eventActions';
import useStyles from '../styles/EventsPage';

const initialDetails = {
  topic: '',
  groupPrice: '',
  soloPrice: '',
  desc: '',
  img: null,
  startDate: '',
  endDate: '',
  videoSubmission: false,
  groupAllowed: false,
  groupLimit: '',
  videoLimit: '',
  imageSubmission: false,
  imageLimit: '',
  pdfSubmission: false,
  pdfLimit: '',
};

const EventsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addEventModal, setAddEventModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(initialDetails);
  const [result, setResult] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [editableEvent, setEditableEvent] = useState('');
  const [editEventModal, setEditEventModal] = useState(false);

  const { allEvents } = useSelector((store) => store.eventReducer);

  useEffect(() => {
    if (allEvents.length === 0) {
      dispatch(getAllEvents());
    }
  }, [dispatch, allEvents]);

  const rowCount = allEvents.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    dispatch(deleteEvent(id));
  };

  const handleDetailsChange = (e) => {
    const { name } = e.target;
    setEventDetails({ ...eventDetails, [name]: e.target.value });
  };

  const handleEditDetailsChange = (e) => {
    const { name } = e.target;
    setEditableEvent({ ...editableEvent, [name]: e.target.value });
  };

  const handleDescChange = (value) => {
    setEventDetails({ ...eventDetails, desc: value });
  };

  const handleEditDescChange = (value) => {
    setEditableEvent({ ...editableEvent, desc: value });
  };

  const handleImageChange = (e) => {
    let file = e.target.files[0];
    setEventDetails({ ...eventDetails, img: file });
  };

  const handleEditImageChange = (e) => {
    let file = e.target.files[0];
    setEditableEvent({ ...editableEvent, img: file });
  };

  const handleToggleChange = (e) => {
    const { name } = e.target;
    setEventDetails({ ...eventDetails, [name]: !eventDetails[name] });
  };

  const handleEditToggleChange = (e) => {
    const { name } = e.target;
    setEditableEvent({ ...editableEvent, [name]: !editableEvent[name] });
  };

  const handleResultChange = (value) => {
    setResult(value);
  };

  const handleFilesChange = (files) => {
    setFiles(files);
  };

  const handleResultClick = (event) => {
    setResultModal(true);
    setSelectedEvent(event.id);
    setResult(event.result);
  };

  const handleEditClick = (event) => {
    const eventObj = { ...event };
    eventObj.pdfSubmission = eventObj.pdfSubmission ? true : false;
    eventObj.imageSubmission = eventObj.imageSubmission ? true : false;
    eventObj.videoSubmission = eventObj.videoSubmission ? true : false;
    eventObj.startDate = new Date(Date.parse(event.start_time))
      .toISOString()
      .split('T')[0];
    eventObj.endDate = new Date(Date.parse(event.end_time))
      .toISOString()
      .split('T')[0];
    delete eventObj.start_time;
    delete eventObj.end_time;
    setEditableEvent(eventObj);
    setEditEventModal(true);
  };

  const handleResultSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('result', result);
    for (const key of Object.keys(files)) {
      formData.append('submission', files[key]);
    }

    dispatch(declareResult(formData, selectedEvent));

    setResult('');
    setFiles([]);
    setSelectedEvent('');
    setResultModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const [syyyy, smm, sdd] = eventDetails.startDate.split('-');
    const [eyyyy, emm, edd] = eventDetails.endDate.split('-');
    const formData = new FormData();
    formData.append('topic', eventDetails.topic);
    formData.append('soloPrice', eventDetails.soloPrice);
    formData.append('groupPrice', eventDetails.groupPrice);
    formData.append('desc', eventDetails.desc);
    formData.append('image', eventDetails.img);
    formData.append('groupAllowed', eventDetails.groupAllowed);
    formData.append(
      'groupLimit',
      eventDetails.groupAllowed ? eventDetails.groupLimit : '0'
    );
    formData.append('videoSubmission', eventDetails.videoSubmission);

    formData.append(
      'videoLimit',
      eventDetails.videoSubmission ? eventDetails.videoLimit : '0'
    );
    formData.append('imageSubmission', eventDetails.imageSubmission);
    formData.append(
      'imageLimit',
      eventDetails.imageSubmission ? eventDetails.imageLimit : '0'
    );
    formData.append('pdfSubmission', eventDetails.pdfSubmission);
    formData.append(
      'pdfLimit',
      eventDetails.pdfSubmission ? eventDetails.pdfLimit : '0'
    );
    formData.append(
      'startTime',
      new Date(parseInt(syyyy), parseInt(smm) - 1, parseInt(sdd) + 1)
    );
    formData.append(
      'endTime',
      new Date(parseInt(eyyyy), parseInt(emm) - 1, parseInt(edd) + 1)
    );

    dispatch(addEvent(formData)).then(()=>{

      setEventDetails(initialDetails);
      dispatch(getAllEvents())
      setAddEventModal(false);
    })

  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const [syyyy, smm, sdd] = editableEvent.startDate.split('-');
    const [eyyyy, emm, edd] = editableEvent.endDate.split('-');
    const formData = new FormData();
    formData.append('topic', editableEvent.topic);
    formData.append('soloPrice', editableEvent.soloPrice);
    formData.append('groupPrice', editableEvent.groupPrice);
    formData.append('desc', editableEvent.desc);
    formData.append('image', editableEvent.img);
    formData.append('groupAllowed', eventDetails.groupAllowed);
    formData.append(
      'groupLimit',
      eventDetails.groupAllowed ? eventDetails.groupLimit : '0'
    );
    formData.append('videoSubmission', editableEvent.videoSubmission);
    formData.append(
      'videoLimit',
      editableEvent.videoSubmission ? editableEvent.videoLimit : '0'
    );
    formData.append('imageSubmission', editableEvent.imageSubmission);
    formData.append(
      'imageLimit',
      editableEvent.imageSubmission ? editableEvent.imageLimit : '0'
    );
    formData.append('pdfSubmission', editableEvent.pdfSubmission);
    formData.append(
      'pdfLimit',
      editableEvent.pdfSubmission ? editableEvent.pdfLimit : '0'
    );
    formData.append(
      'startTime',
      new Date(parseInt(syyyy), parseInt(smm) - 1, parseInt(sdd) + 1)
    );
    formData.append(
      'endTime',
      new Date(parseInt(eyyyy), parseInt(emm) - 1, parseInt(edd) + 1)
    );

    dispatch(updateEvent(editableEvent.id, formData)).then(()=>{
      dispatch(getAllEvents())
      setEditEventModal(false);
    })

  };
  console.log('allEvents:', allEvents);
console.log('rowCount:', rowCount);
console.log('page:', page);
console.log('rowsPerPage:', rowsPerPage);
console.log('result:', result);
console.log('resultModal:', resultModal);


  return (
    <MiniDrawer>
      <div className={classes.EventsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => setAddEventModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Events Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Title
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Solo Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Group Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Start Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    End Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Result
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(allEvents) && allEvents.length > 0 ? (
  allEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((event, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={classes.tableRow}
                      >
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{event.topic}</TableCell>
                        <TableCell align='center'>{event.soloPrice}</TableCell>
                        <TableCell align='center'>{event.groupPrice}</TableCell>
                        <TableCell align='center'>
                          {event.start_time.replace('6:30 PM', '')}
                        </TableCell>
                        <TableCell align='center'>
                          {event.end_time.replace('6:30 PM', '')}
                        </TableCell>
                        <TableCell align='center'>
                          <VisibilityIcon
                            onClick={() => handleResultClick(event)}
                            className={classes.resultIcon}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <EditIcon
                            onClick={() => handleEditClick(event)}
                            className={classes.editIcon}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(event.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                
                ):(<TableRow>
                    <TableCell colSpan={9} align='center'>{allEvents.message}</TableCell>
                </TableRow>)
                
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component='div'
            count={typeof rowCount === 'undefined' ? 0 : rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {resultModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setResultModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Declare/Modify Result</h1>
              <form
                className={classes.eventModalForm}
                onSubmit={handleResultSubmit}
              >
                <p className={classes.descText}>Result</p>
                <ReactQuill
                  value={result}
                  onChange={handleResultChange}
                  className={classes.descBox}
                />
                <DropzoneArea
                  acceptedFiles={['application/pdf', 'video/mp4', 'image/*']}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{
                    container: { spacing: 1, direction: 'row' },
                  }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText='Selected files'
                  filesLimit={10}
                  onChange={handleFilesChange}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.eventModalButton}
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        )}
        {addEventModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddEventModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Add Event</h1>
              <form className={classes.eventModalForm} onSubmit={handleSubmit}>
                <div className={classes.inputRow}>
                  <TextField
                    name='topic'
                    required
                    label='Title'
                    size='small'
                    variant='outlined'
                    value={eventDetails.topic}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                  <div className={classes.imgDiv}>
                    <p className={classes.descText}>Event Image</p>
                    <Input
                      type='file'
                      inputProps={{ accept: 'image/*' }}
                      fullWidth
                      className={classes.eventImgInput}
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    type='number'
                    name='soloPrice'
                    required
                    label='Solo Price'
                    size='small'
                    variant='outlined'
                    value={eventDetails.soloPrice}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                  <TextField
                    type='number'
                    name='groupPrice'
                    required
                    label='Group Price'
                    size='small'
                    variant='outlined'
                    value={eventDetails.groupPrice}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='groupAllowed'
                        checked={eventDetails.groupAllowed}
                        onChange={handleToggleChange}
                        color='primary'
                      />
                    }
                    label='Is Group allowed'
                  />
                  {eventDetails.groupAllowed && (
                    <TextField
                      type='number'
                      name='groupLimit'
                      required
                      label='Group Members Limit'
                      size='small'
                      variant='outlined'
                      value={eventDetails.groupLimit}
                      className={classes.eventModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div>
                <p className={classes.descText}>Description</p>
                <ReactQuill
                  value={eventDetails.desc}
                  onChange={handleDescChange}
                  className={classes.descBox}
                />
                <div className={classes.inputRow}>
                  <TextField
                    name='startDate'
                    required
                    label='Start Date'
                    type='date'
                    size='small'
                    variant='outlined'
                    value={eventDetails.startDate}
                    onChange={handleDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    name='endDate'
                    required
                    label='End Date'
                    type='date'
                    size='small'
                    variant='outlined'
                    value={eventDetails.endDate}
                    onChange={handleDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='imageSubmission'
                        checked={eventDetails.imageSubmission}
                        onChange={handleToggleChange}
                        color='primary'
                      />
                    }
                    label='Is image allowed'
                  />
                  {eventDetails.imageSubmission && (
                    <TextField
                      type='number'
                      name='imageLimit'
                      required
                      label='Image Limit'
                      size='small'
                      variant='outlined'
                      value={eventDetails.imageLimit}
                      className={classes.eventModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='videoSubmission'
                        checked={eventDetails.videoSubmission}
                        onChange={handleToggleChange}
                        color='primary'
                      />
                    }
                    label='Is video allowed'
                  />
                  {eventDetails.videoSubmission && (
                    <TextField
                      type='number'
                      name='videoLimit'
                      required
                      label='Video Limit'
                      size='small'
                      variant='outlined'
                      value={eventDetails.videoLimit}
                      className={classes.eventModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='pdfSubmission'
                        checked={eventDetails.pdfSubmission}
                        onChange={handleToggleChange}
                        color='primary'
                      />
                    }
                    label='Is pdf allowed'
                  />
                  {eventDetails.pdfSubmission && (
                    <TextField
                      type='number'
                      name='pdfLimit'
                      required
                      label='File Limit'
                      size='small'
                      variant='outlined'
                      value={eventDetails.pdfLimit}
                      className={classes.eventModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
        {editEventModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setEditEventModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Edit Event</h1>
              <form
                className={classes.eventModalForm}
                onSubmit={handleSaveChanges}
              >
                <div className={classes.inputRow}>
                  <TextField
                    name='topic'
                    required
                    label='Title'
                    size='small'
                    variant='outlined'
                    value={editableEvent.topic}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  <div className={classes.imgDiv}>
                    <p className={classes.descText}>Event Image</p>
                    <Input
                      type='file'
                      inputProps={{ accept: 'image/*' }}
                      fullWidth
                      className={classes.eventImgInput}
                      onChange={handleEditImageChange}
                    />
                  </div>
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    type='number'
                    name='soloPrice'
                    required
                    label='Solo Price'
                    size='small'
                    variant='outlined'
                    value={editableEvent.soloPrice}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  <TextField
                    type='number'
                    name='groupPrice'
                    required
                    label='Group Price'
                    size='small'
                    variant='outlined'
                    value={editableEvent.groupPrice}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='groupAllowed'
                        checked={editableEvent.groupAllowed}
                        onChange={handleEditToggleChange}
                        color='primary'
                      />
                    }
                    label='Is group allowed'
                  />
                  {editableEvent.groupAllowed && (
                    <TextField
                      type='number'
                      name='groupLimit'
                      required
                      label='Group Members Limit'
                      size='small'
                      variant='outlined'
                      value={editableEvent.groupLimit}
                      className={classes.eventModalInput}
                      onChange={handleEditDetailsChange}
                    />
                  )}
                </div>
                <p className={classes.descText}>Description</p>
                <ReactQuill
                  value={editableEvent.desc}
                  onChange={handleEditDescChange}
                  className={classes.descBox}
                />
                <div className={classes.inputRow}>
                  <TextField
                    name='startDate'
                    required
                    label='Start Date'
                    type='date'
                    size='small'
                    variant='outlined'
                    value={editableEvent.startDate}
                    onChange={handleEditDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    name='endDate'
                    required
                    label='End Date'
                    type='date'
                    size='small'
                    variant='outlined'
                    value={editableEvent.endDate}
                    onChange={handleEditDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='imageSubmission'
                        checked={editableEvent.imageSubmission}
                        onChange={handleEditToggleChange}
                        color='primary'
                      />
                    }
                    label='Is image allowed'
                  />
                  {editableEvent.imageSubmission && (
                    <TextField
                      type='number'
                      name='imageLimit'
                      required
                      label='Image Limit'
                      size='small'
                      variant='outlined'
                      value={editableEvent.imageLimit}
                      className={classes.eventModalInput}
                      onChange={handleEditDetailsChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='videoSubmission'
                        checked={editableEvent.videoSubmission}
                        onChange={handleEditToggleChange}
                        color='primary'
                      />
                    }
                    label='Is video allowed'
                  />
                  {editableEvent.videoSubmission && (
                    <TextField
                      type='number'
                      name='videoLimit'
                      required
                      label='Video Limit'
                      size='small'
                      variant='outlined'
                      value={editableEvent.videoLimit}
                      className={classes.eventModalInput}
                      onChange={handleEditDetailsChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name='pdfSubmission'
                        checked={editableEvent.pdfSubmission}
                        onChange={handleEditToggleChange}
                        color='primary'
                      />
                    }
                    label='Is pdf allowed'
                  />
                  {editableEvent.pdfSubmission && (
                    <TextField
                      type='number'
                      name='pdfLimit'
                      required
                      label='File Limit'
                      size='small'
                      variant='outlined'
                      value={editableEvent.pdfLimit}
                      className={classes.eventModalInput}
                      onChange={handleEditDetailsChange}
                    />
                  )}
                </div>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default EventsPage;
