import * as api from './api';
import {
  GET_ALL_EXAMS,
  ADD_EXAM,
  UPDATE_EXAM,
  DELETE_EXAM,
  ADD_EXAM_CHAPTER,
  UPDATE_EXAM_CHAPTER,
  DELETE_EXAM_CHAPTER,
} from '../actionTypes';

export const getAllExams = () => async (dispatch) => {
  try {
    const { data } = await api.getAllExams();
    dispatch({
      type: GET_ALL_EXAMS,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const addExams = (formData) => async (dispatch) => {
  try {
    const response = await api.addExam(formData);
    dispatch({ type: ADD_EXAM, payload: response.data });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateExams = (id, formData) => async (dispatch) => {
  try {
    const {data} =await api.updateExam(id, formData);
    dispatch({ type: UPDATE_EXAM ,payload:data});

  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteExams = (id) => async (dispatch) => {
  try {
    await api.deleteExam(id);
    dispatch({
      type: DELETE_EXAM,
      payload: id,
    });
    dispatch(getAllExams());
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const addExamChapter = (formData) => async (dispatch) => {
  try {
    const response = await api.addExamChapter(formData);
    dispatch({ type: ADD_EXAM_CHAPTER, payload: response.data });
    dispatch(getAllExams());
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteExamChapter = (chapterId, examId) => async (dispatch) => {
  try {
    await api.deleteExamChapter(chapterId);
    dispatch({
      type: DELETE_EXAM_CHAPTER,
      payload: { chapterId, examId },
    });
    dispatch(getAllExams());
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};
