import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  Input,
  MenuItem
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { addLecturePrices, getLecturePrice } from '../redux/actions/lectureActions';
import { getAllTeachers,createTeacher,updateTeacher,deleteTeacher, getSchoolNames } from '../redux/actions/teacherActions';
import useStyles from '../styles/EventsPage';
//import { makeStyles} from '@material-ui/core/styles';
//import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const initialDetails = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password:'',
  school:''
  
};

const Teachers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  const [teacherModal,setTeacherModal]=useState(false)
  const [workshopDetails, setWorkshopDetails] = useState(initialDetails);
  const [editableWorkshop, setEditableWorkshop] = useState('');
  const [editWorkshopModal, setEditWorkshopModal] = useState(false);

  const { allWorkshops } = useSelector((store) => store.workshopReducer);
  const {getLecturePrices}=useSelector((store)=> store.lectureReducer )
  const { teacherData,instituteData } = useSelector((store) => store.teacherReducer);

  
  console.log('instituteData', instituteData)

  useEffect(() => {

    
    console.log('trigger') 
       if (getLecturePrices==0) {
      dispatch(getLecturePrice());
      dispatch(getSchoolNames())
      
    }
  }, [dispatch, getLecturePrices]);


  useEffect(()=>{
    if(teacherData==0){
        dispatch(getAllTeachers())
    }
  },[dispatch,teacherData])

const Data=teacherData?.teacher;
 
const SchoolNames = ["Harvard University", "Stanford University", "MIT", "University of Cambridge"];
  const rowCount = teacherData?.teacher?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    dispatch(deleteTeacher(id)).then(()=>{
      dispatch(getAllTeachers())

    })
   
  };

  const handleDetailsChange = (e) => {
    const { name } = e.target;
    setWorkshopDetails((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  // const handleChangeDetails = (e) => {
  //   const { name } = e.target;
  //   // setSubjectDetails({ ...subjectDetails, [name]: e.target.value });
  //   setSubjectDetails((prevState) => ({ ...prevState, [name]: e.target.value }));

  // };

  const handleEditDetailsChange = (e) => {
    const { name } = e.target;
    setEditableWorkshop({ ...editableWorkshop, [name]: e.target.value });
  };
  const [isManualEntry, setIsManualEntry] = useState(false); // State to track if manual entry is selected

  const handleSchoolChange = (e) => {
    const value = e.target.value;
    if (value === 'Other') {
      setIsManualEntry(true);
      setWorkshopDetails({ ...workshopDetails, school: '' }); // Clear previous selection
    } else {
      setIsManualEntry(false);
      setWorkshopDetails({ ...workshopDetails, school: value });
    }
  };


  const handleEditClick = (workshop) => {
    
    setEditableWorkshop(workshop);
    setEditWorkshopModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('workshopDetails', workshopDetails)
    // const formData = new FormData();


  const data = {
    firstname: workshopDetails.firstname,
    lastname: workshopDetails.lastname,
    email: workshopDetails.email,
    phone: workshopDetails.phone,
    password: workshopDetails.password,
    school:workshopDetails.school
  };
    // formData.append("firstname", workshopDetails.firstname);
    // formData.append("lastname", workshopDetails.lastname); 
    // formData.append("email", workshopDetails.email); 
    // formData.append("phone", workshopDetails.phone);
    // formData.append("password", workshopDetails.password);


// console.log('formData', formData)
    dispatch(createTeacher(data)).then(()=>{
      setWorkshopDetails(initialDetails)
      dispatch(getAllTeachers())
      dispatch(getSchoolNames())
      setTeacherModal(false);
    })
  
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const updatedData = {
      id:editableWorkshop.id,
      firstname: editableWorkshop.firstname,
      lastname: editableWorkshop.lastname,
      email: editableWorkshop.email,
      phone: editableWorkshop.phone,
      password: editableWorkshop.password,
      school:editableWorkshop.school
    };
  
  
    dispatch(updateTeacher(updatedData)).then(()=>{
      setEditWorkshopModal(false)
      dispatch(getAllTeachers())
      dispatch(getSchoolNames())
    })
   
  };

  return (
    <MiniDrawer>
      /*<div className={classes.teachersPageContainer}>*/
        <div className={classes.buttonContainer}>
          <Button
        
          color="primary"
            startIcon={<AddIcon />}
            onClick={() => setTeacherModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Teacher Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Teacher Name
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Email
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                   Phone
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Password
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={classes.tableRow}
                      >
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{item?.firstname + " "+item?.lastname}</TableCell>
                        <TableCell align='center'>{item?.email}</TableCell>
                        <TableCell align='center'>{item?.phone}</TableCell>
                        <TableCell align='center'>
                          {item?.temp_password}
                        </TableCell>
                        <TableCell align='center'>
                          <EditIcon
                            onClick={() => handleEditClick(item)}
                            className={classes.editIcon}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(item.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {teacherModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setTeacherModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Add Teachers</h1>
              <form className={classes.eventModalForm} onSubmit={handleSubmit}>
                <div className={classes.inputRow}>
                  <TextField
            
                    name="firstname"
                    required
                    label="First Name"
                 
                    variant="outlined"
                    value={workshopDetails.firstname}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  >
                 
                

                  </TextField>
                  <TextField
                  
                    name="lastname"
                    required
                    label="Last Name"
                  
                    variant="outlined"
                    value={workshopDetails.lastname}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  >
                    
                    </TextField>
                </div>
                
               
                <div className={classes.workshopImgTextDiv}>
                  
                </div>
                <div className={classes.inputRow}>
                <TextField
                 
                    name="email"
                    required
                    label="Email"
                  
                    variant="outlined"
                    value={workshopDetails.email}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  >

                    </TextField>
                   <TextField
                    type='number'
                    name="phone"
                    required
                    label="Phone Number"
                   
                    variant="outlined"
                    value={workshopDetails.phone}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                
                </div>

                <div className={classes.inputRow}>
              
                <Autocomplete
  className={classes.eventModalInput}
  freeSolo
  id="size-small-outlined"
  options={instituteData?.schoolNames}
  value={workshopDetails.school}
  onChange={(event, newValue) => {
    setWorkshopDetails(prevDetails => ({
      ...prevDetails,
      school: newValue || '', // Set school to the selected or typed value
    }));
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="School/College"
      required
      variant="outlined"
      
      onChange={(event) => {
        setWorkshopDetails(prevDetails => ({
          ...prevDetails,
          school: event.target.value, // Set school to the typed value
        }));
      }}
    />
  )}
/>



                <TextField
                 type='text'
                 name="password"
                 required
                 label="Password"
             
                 variant="outlined"
                 value={workshopDetails.password}
                 className={classes.eventModalInput}
                 onChange={handleDetailsChange}
               >
                </TextField>


                </div>
            
              
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
        {editWorkshopModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setEditWorkshopModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Edit Teachers</h1>
              <form className={classes.eventModalForm} onSubmit={handleSaveChanges}>
                <div className={classes.inputRow}>
                  <TextField
                  
                    name="firstname"
                    required
                    label="First Name"
                  
                    variant="outlined"
                    value={editableWorkshop.firstname}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                
      
                  <TextField
                
                    name="lastname"
                    required
                    label="Last Name"
                   
                    variant="outlined"
                    value={editableWorkshop.lastname}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                 >


                  </TextField>
                </div>
               
              
                <div className={classes.workshopImgTextDiv}>
                  
                </div>
                <div className={classes.inputRow}>
                <TextField
                    
                    name="email"
                    required
                    label="Email"
                   
                    variant="outlined"
                    value={editableWorkshop.email}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  >

                  </TextField>
                  <TextField
                    type="number"
                    name="phone"
                    required
                    label="Phone"
                   
                    variant="outlined"
                    value={editableWorkshop.phone}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />


                  {/* <TextField
                    type="text"
                    name="password"
                    required
                    label="Password"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop.password}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  /> */}
                </div>
                <div className={classes.inputRow}>

                <Autocomplete
  className={classes.eventModalInput}
  freeSolo
  id="size-small-outlined"
  options={instituteData?.schoolNames}
  value={editableWorkshop.school}
  onChange={(event, newValue) => {
    setWorkshopDetails(prevDetails => ({
      ...prevDetails,
      school: newValue || '', // Set school to the selected or typed value
    }));
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="School/College"
      required
      variant="outlined"
      size="small"  // Apply size="small" to the TextField
      onChange={(event) => {
        setWorkshopDetails(prevDetails => ({
          ...prevDetails,
          school: event.target.value, // Set school to the typed value
        }));
      }}
    />
  )}
/>

                </div>
              
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};


export default Teachers;
