import axios from "axios";
import Cookies from "js-cookie";

  //PRODUCTION
const API = axios.create({
  baseURL: "https://api.enroutingcareers.com/", 
});



// //DEVELOPMENT
// const API = axios.create({
  
//   baseURL: "http://localhost:8080/",
// });


API.interceptors.request.use((req) => {
  if (Cookies.get("adminJWT")) {
    req.headers["x-access-token"] = Cookies.get("adminJWT");
  }
  return req;
});

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};


//=============================> ADMIN LOGIN & SIGNUP ENDPOINTS <===============================================
export const getAdmin = () => API.get("/api/admin/profile");
export const login = (loginData) =>API.post("/api/auth/admin/signin", loginData);


//=============================>  BOARDS ENDPOINTS <===============================================
export const getAllBoards = () => API.get("/admin/boards/all");
export const addBoard = (name) => API.post("/admin/boards/create", { name });
export const deleteBoard = (id) => API.delete(`/admin/boards/${id}`);


//=============================>  STANDARD/CLASS ENDPOINTS <===============================================
export const getAllStandards = () => API.get("/admin/standards/all");
export const addStandard = (c,registrationFees) =>API.post("/admin/standards/create", { class: c,registrationFees:registrationFees });
export const deleteStandard = (id) => API.delete(`/admin/standards/${id}`);

//=============================>  SUBJECT/COURCES ENDPOINTS <===============================================
export const getAllSubjects = () => API.get("/admin/courses/all");
export const addSubject = (formData) =>API.post("/admin/course/create", formData);
export const deleteSubject = (id) => API.delete(`/admin/course/${id}`);
export const updateSubject = (id, formData) =>API.put(`/admin/course/${id}`, formData);

//=============================>  CHAPTER ENDPOINTS <===============================================
export const addChapter = (formData) =>API.post("/admin/chapter/create", formData);
export const deleteChapter = (id) => API.delete(`/admin/chapter/${id}`);
export const updateChapter = (id, formData) =>API.put(`/admin/chapter/${id}`, formData);
export const uploadNotes = (formData, id) =>API.put(`/admin/chapter/upload/notes/${id}`, formData, config);
export const uploadLecture = (formData, id) =>API.put(`/admin/chapter/upload/video/${id}`, formData, config);

//=============================>  EVENT ENDPOINTS <===============================================
export const getAllEvents = () => API.get("/api/events/all");
export const addEvent = (formData) =>API.post("/api/events/create", formData, config);
export const updateEvent = (id, formData) =>API.put(`/api/events/update/${id}`, formData, config);
export const deleteEvent = (id) => API.delete(`/api/events/delete/${id}`);
export const declareResult = (formData, id) =>API.put(`/api/events/result/${id}`, formData, config);

//=============================>  WORKSHOP ENDPOINTS <===============================================
export const getAllWorkshops = () => API.get("/api/workshops/all");
export const addWorkshop = (formData) =>API.post("/api/workshop/create", formData, config);
export const updateWorkshop = (id, formData) =>API.put(`/api/workshop/update/${id}`, formData, config);
export const deleteWorkshop = (id) => API.delete(`/api/workshop/delete/${id}`);

//=============================>  TESTIMONIAL ENDPOINTS <===============================================
export const getAllTestimonials = () => API.get("/api/testimonials");
export const addTestimonial = (formData) =>API.post("/api/testimonials/create", formData, config);
export const updateTestimonial = (id, formData) =>API.put(`/api/testimonial/${id}`, formData, config);
export const deleteTestimonial = (id) => API.delete(`/api/testimonial/${id}`);

//=============================>  BLOGS ENDPOINTS <===============================================
export const getAllBlogs = () => API.get("/api/blogs/all");
export const addBlog = (formData) =>API.post("/api/blogs/create", formData, config);
export const updateBlog = (id, formData) =>API.put(`/api/blogs/${id}`, formData, config);
export const deleteBlog = (id) => API.delete(`/api/blogs/${id}`);

// =============================> PROJECT ENDPOINTS <===========================================
export const createProject=(formData)=>API.post('/admin/project/create',formData,config)
export const getProjects=()=>API.get('/admin/project/all')
export const updateProject=(id,formData)=>API.put(`/admin/project/${id}`,formData,config)
export const removeProject=(id)=>API.delete(`/admin/project/${id}`)

//=============================>  USER RELATED ENDPOINTS <===============================================
export const getAllUsers = () => API.post("/api/user/all");
export const getUserPurchases = (id) =>API.post("/api/user/all/purchases", { userId: id });
export const getAllPurchases=()=>API.get('/api/all/purchases')

//========================> LIVE LECTURE PRICES ENPOINTS <=========================================
export const getLecturePrice=()=> API.get('/admin/lectures/getLectures')
export const addLecturePrices=(formData)=> API.post('/admin/lectures/create',formData)
export const updateLecturePrices=(id,formData)=>API.put(`/admin/lectures/${id}`,formData)
export const deleteLecturePrice=(id)=>API.delete(`/admin/lectures/${id}`)

//=============================>  TEACHER RELATED ENDPOINTS <===============================================
export const getTeacherDetails=()=>API.get('/api/teacher/getAllTeacher');
export const createTeacher=(formData)=>API.post('/api/teacher/signup',formData)
export const updateTeacher=(formData)=>API.put(`/api/teacher/update`,formData)
export const removeTeacher=(id)=>API.delete(`/api/teacher/${id}`)



//export const getAllExams = () => API.get('/exams');
//export const addExam = (examData) => API.post('/exams', examData);
//export const updateExam = (id, examData) => API.put(`/exams/${id}`, examData);
//export const deleteExam = (id) => API.delete(`/exams/${id}`);

export const getAllExams = () => API.get("/api/exam/findAll");
export const addExam = (formData) => API.post("/admin/exam/create", formData);
export const deleteExam = (id) => API.delete(`/api/exam/${id}`);
export const updateExam = (id, formData) => API.patch(`/api/admin/exam/${id}`, formData);
export const addExamChapter = (formData) => API.post("/admin/exam/chapter/create", formData);
export const deleteExamChapter = (id) => API.delete(`/admin/exam/chapter/${id}`);



export const getAllPendingUsers=()=>API.get('/api/teacher/users/pending');
export const getAllUsersByTeacher=()=>API.get('/api/teacher/users/created');
export const createSingleUserByTeacher=(formData)=>API.post("/app/teacher/user/create",formData)
export const createBulkUser=(formData)=>API.post("/api/teacher/bulk_users",formData)



export const allInstitutes=()=>API.get('/api/schoolnames')
export const userRegisterFees=()=>API.get('/user/register/fees')