import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-root': {
      padding: '0.5rem'
    }
  },
  ChaptersPageTitle: {
    textAlign: 'center',
    marginTop: '4rem'
  },
  ChaptersPageContainer: {
    width: '80%',
    margin: '0 auto',
  },
  tableHeaderCell: {
    backgroundColor: '#4059b5',
    color: 'white',
    fontWeight: 600,
  },
  editIcon: {
    fontSize: '1.3rem'
  }
}));