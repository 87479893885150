import {
  GET_ALL_SUBJECTS,
  ADD_SUBJECT,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
  ADD_CHAPTER,
  UPDATE_CHAPTER,
  DELETE_CHAPTER,
} from '../actionTypes';


const initialState = {
  allSubjects: [],
  allChapters:[]
};



const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBJECTS:
      return {
        ...state,
        allSubjects: action?.payload,
      };

    case DELETE_SUBJECT:
      return {
        ...state,
        allSubjects: state.allSubjects.filter(subject => subject.id !== action.payload)
      };

    case DELETE_CHAPTER:
      let newArray = [...state.allSubjects];
      newArray.forEach(sub => {
        if (sub.id === action?.payload?.subjectId) {
          sub.chapters = sub.chapters.filter(chapter => chapter.id !== action?.payload?.chapterId);
        }
      });
      return {
        ...state,
        allSubjects: newArray
      };

    case ADD_SUBJECT:
      console.log("Previous state:", state.allSubjects);
      console.log("New subject:", action.payload);
        const updatedSubject ={
          ...state,
          allSubjects: [...state.allSubjects, action.payload],
          
        };
        return updatedSubject
        
      
    case UPDATE_SUBJECT:
     return state;
   
     case ADD_CHAPTER: 
        const { subjectId, chapter } = action.payload;
        const updatedSubjects = state.allSubjects.map((subject) =>
          subject.id === subjectId
            ? { ...subject, chapters: [...subject.chapters, chapter] }
            : subject
        );
        return {
          ...state,
          allSubjects: updatedSubjects,
        };
    case UPDATE_CHAPTER:
      return state;

    default:
      return state;
  }
};

export default subjectReducer;
