import { CREATE_PROJECT, DELETE_PROJECT, GET_PROJECT, UPDATE_PROJECT } from "../actionTypes";
import * as api from "../actions/api/index";

export const createProject = (formData) => async (dispatch) => {
  console.log('formData from action', formData)
  try {
    const { data } = await api.createProject(formData);
    dispatch({
      type: CREATE_PROJECT,
      payload: data,
    });

    return data;
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : "Something went wrong";
    console.log(message);
  }
};

export const getProjects = () => async (dispatch) => {
  try {
    const { data } = await api.getProjects();
    console.log('data from action', data)
    dispatch({
      type: GET_PROJECT,
      payload: data,
    });

    return data;
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : "Something went wrong";
    console.log(message);
  }
};

export const updateProject = (id,formData) => async (dispatch) => {
 
    try {
      const { data } = await api.updateProject(id,formData);

      console.log('data from action',data)
      dispatch({
        type: UPDATE_PROJECT,
        payload: data,
      });
  
      return data;
    } catch (err) {
      const message = err?.response?.data?.message
        ? err.response.data.message
        : "Something went wrong";
      console.log(message);
    }
  };


export const removeProject = (id) => async (dispatch) => {
    try {
      const { data } = await api.removeProject(id);
      dispatch({
        type: DELETE_PROJECT,
        payload: data,
      });
      return data;
    } catch (err) {
      const message = err?.response?.data?.message
        ? err.response.data.message
        : "Something went wrong";
      console.log(message);
    }
  };
