import { GET_ALL_STANDARDS, ADD_STANDARD, DELETE_STANDARD } from '../actionTypes';

const initialState = {
  allStandards: [],
};

const standardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STANDARDS:
      return {
        ...state,
        allStandards: action?.payload,
      };
    case ADD_STANDARD:
      
      const updatedState = {
        ...state,
        allStandards: [...state.allStandards, action.payload],
      };
      console.log('Updated state:', updatedState); // Log updated state
      return updatedState;
    case DELETE_STANDARD:
      return {
        ...state,
        allStandards: state.allStandards.filter(standard => standard.id !== action.payload)
      };
    default:
      return state;
  }
};

export default standardReducer;
