import React, { useState, useRef, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import EditIcon from "@mui/icons-material/Edit";
import useStyles from '../styles/UsersListPage';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CreateUserModal from './CreateUserModal';
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersByTeacher, getAllPendingUsers, createBulkUser } from "../redux/actions/BulkListAction";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import GetAppIcon from '@material-ui/icons/GetApp';


const DummyUsersTable = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openForm, setOpenForm] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedFormData, setSelectedFormData] = useState(null);
    const [status, setStatus] = useState('successful');
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(null);
    const { legitUserData, pendingData } = useSelector((store) => store?.BulkUserReducer);

    useEffect(() => {
        dispatch(getAllUsersByTeacher());
        dispatch(getAllPendingUsers());
    }, [dispatch]);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
        setPage(0); // Reset page to 0 when changing status
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCreateButtonClick = () => {
        setOpenForm(true);
    };

    const handleFormClose = () => {
        setOpenForm(false);
    };

    const handleEditClick = (formData) => {
        setSelectedFormData(formData);
        setIsAddOpen(true);
        setIsEdit(true);
    };

    const handleViewClose = () => {
        setOpenView(false);
        setSelectedUser(null);
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleFileUpload = async () => {
        if (selectedFile && (selectedFile.type === "application/vnd.ms-excel" || selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            setUploading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);

            try {
                await dispatch(createBulkUser(formData)).then(()=>{
                
                setUploading(false);
                dispatch(getAllUsersByTeacher());
                dispatch(getAllPendingUsers());
                setSelectedFile(null)
                })
               

                toast.success("File uploaded successfully!");
            } catch (error) {
                setUploading(false);
                const errorMessage = error.response?.data?.message || "An error occurred during the upload";
                toast.error(errorMessage);
            }
        } else {
            toast.error("Please select a valid Excel file (.xls or .xlsx)");
        }
    };

    // Determine which data to display based on selected status
    const dataToDisplay = status === 'successful' ? legitUserData?.data : pendingData?.data;

    // Function to download data as Excel
    const handleDownloadExcel = () => {
        const data = dataToDisplay.map((user, index) => ({
            "Sr. No.": index + 1,
            "Full Name": `${user.firstname} ${user.lastname}`,
            "Email": user.email,
            "Phone Number": user.phone,
            "Standard": user.standard,
            "Section": user.section,
            "School/College": user.school || user.college,
            ...(status === 'successful' && { "Temporary Password": user.temp_pass }),
            ...(status === 'pending' && { "Reason": user.validation_errors })
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

        // Download the Excel file
        XLSX.writeFile(workbook, `${status}_users_list.xlsx`);
    };
    const buttonStyles = (isHovered) => ({
        backgroundColor: isHovered ? "#1976d2" : "white", // Change color on hover
        color: isHovered ? "white" : "#1976d2",          // Change text color on hover
        transition: "background-color 0.3s ease, color 0.3s ease",
        border: "1px solid #1976d2",
        marginRight: '10px'
    });
    return (
        <MiniDrawer>
            <div className={classes.ListPageContainer}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="status-label" className={classes.label}>Status</InputLabel>
                    <Select
                        labelId="status-label"
                        id="statusSelect"
                        value={status}
                        onChange={handleStatusChange}
                        className={classes.select}
                        label="Status"
                    >
                        <MenuItem value="successful" className={classes.menuItem}>Successful</MenuItem>
                        <MenuItem value="pending" className={classes.menuItem}>Pending</MenuItem>
                    </Select>
                </FormControl>

                <div className={classes.buttonContainer}>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleCreateButtonClick}
                        startIcon={<AddIcon />}
                        
                        style={buttonStyles(hovered === 'create')}
                        onMouseEnter={() => setHovered('create')}
                        onMouseLeave={() => setHovered(null)}
                    >
                        CREATE
                    </Button>
                    <CreateUserModal open={openForm} onClose={handleFormClose} />
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleUploadClick}
                        startIcon={<CloudUploadIcon />}
                        style={buttonStyles(hovered === 'upload')}
                        onMouseEnter={() => setHovered('upload')}
                        onMouseLeave={() => setHovered(null)}
                    >
                        Upload Excel
                    </Button>
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    {selectedFile && (
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleFileUpload}
                            disabled={uploading}
                            style={buttonStyles(hovered === 'submit')}
                            onMouseEnter={() => setHovered('submit')}
                            onMouseLeave={() => setHovered(null)}
                        >
                            {uploading ? <CircularProgress size={24} /> : 'Submit'}
                        </Button>
                    )}
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={handleDownloadExcel}
                        startIcon={<GetAppIcon />}
                        style={buttonStyles(hovered === 'download')}
                        onMouseEnter={() => setHovered('download')}
                        onMouseLeave={() => setHovered(null)}
                    >
                        Download Excel
                    </Button>
                </div>


                <Paper>
                    <TableContainer>
                        <Table stickyHeader aria-label="Dummy Users Table" className={classes.root}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell className={classes.tableHeaderCell} align="center">Sr. No.</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">User Id</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">Full Name</TableCell>
                                    {status === 'successful' && <TableCell className={classes.tableHeaderCell} align="center">Password</TableCell>}
                                    <TableCell className={classes.tableHeaderCell} align="center">Email</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">Phone Number</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">Standard</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">Section</TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">School/College</TableCell>
                                    {status !== "successful" && <TableCell className={classes.tableHeaderCell} align="center">Reason</TableCell>}
                                    {status !== "successful" && <TableCell className={classes.tableHeaderCell} align="center">Action</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataToDisplay && dataToDisplay.length > 0 ? (
                                    dataToDisplay.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                                        <TableRow key={user.id} className={classes.tableRow}>
                                            <TableCell align="center" className={classes.tableCell}>{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell align="center" className={classes.tableCell}>{user?.custom_id || "NA"}</TableCell>
                                            <TableCell align="center" className={classes.tableCell}>{`${user.firstname} ${user.lastname}`}</TableCell>
                                            {status === 'successful' && <TableCell align="center" className={classes.tableCell}>{user.temp_pass}</TableCell>}
                                            <TableCell align="center" className={classes.tableCell}>{user.email}</TableCell>
                                            <TableCell align="center" className={classes.tableCell}>{user.phone}</TableCell>
                                            <TableCell align="center" className={classes.tableCell}>{user.standard}</TableCell>
                                            <TableCell align="center" className={classes.tableCell}>{user.section}</TableCell>
                                            <TableCell align="center" className={classes.tableCell}>{user.school || user.college}</TableCell>
                                            {status !== 'successful' && <TableCell align="center" className={classes.tableCell}>{user.validation_errors}</TableCell>}
                                            {status !== 'successful' && (
                                                <TableCell align="center" className={classes.tableCell}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => handleEditClick(user)}
                                                    >   Edit
                                                        <EditIcon />
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            No {status} users found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={dataToDisplay ? dataToDisplay.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            {isAddOpen && (
                <CreateUserModal
                    formData={selectedFormData}
                    open={isAddOpen}
                    onClose={() => setIsAddOpen(false)}
                    isEdit={isEdit}
                />
            )}
        </MiniDrawer>
    );
};

export default DummyUsersTable;
