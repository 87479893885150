import { GET_ALL_BOARDS, ADD_BOARD, DELETE_BOARD } from '../actionTypes';

const initialState = {
  allBoards: [],
};

const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BOARDS:
      return {
        ...state,
        allBoards: action?.payload,
      };
    case ADD_BOARD:
      const updatedState = {
        ...state,
        allBoards: [...state.allBoards, action.payload],
      };
      console.log('Updated state:', updatedState); // Log updated state
      return updatedState;
    case DELETE_BOARD:
      return {
        ...state,
        allBoards: state.allBoards.filter(board => board.id !== action.payload)
      };
    default:
      return state;
  }
};

export default boardReducer;
