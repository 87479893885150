
import {GET_TEACHER_DETAILS,CREATE_TEACHERS,UPDATE_TEACHER,DELETE_TEACHER,ALL_INSTITUTE_NAME} from '../actionTypes'
import  * as api from './api'


export const getAllTeachers=()=>async (dispatch)=>{
try {
    const {data}= await api.getTeacherDetails();
    console.log('data', data)
    dispatch({
        type:GET_TEACHER_DETAILS,
        payload:data
    })
} catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
}
}


export const createTeacher=(formData)=>async(dispatch)=>{
try {

    const {data}=await api.createTeacher(formData)
    console.log('data', data)

    dispatch({
        type:CREATE_TEACHERS,
        payload:data
    })
    
} catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
}
}


export const updateTeacher=(formData)=>async(dispatch)=>{
    try {

        const {data}=await api.updateTeacher(formData);
        console.log('data', data)
        dispatch({
            type:UPDATE_TEACHER,
            payload:data

        })
    } catch (err) {
        const message = err?.response?.data?.message
        ? err.response.data.message
        : 'Something went wrong';
      console.log(message);
        
    }
}

export const deleteTeacher=(id)=>async(dispatch)=>{


    try {
        const {data}=await api.removeTeacher(id)

    dispatch({
        type:DELETE_TEACHER,
        payload:data
    })
    } catch (err) {
        const message = err?.response?.data?.message
        ? err.response.data.message
        : 'Something went wrong';
      console.log(message);
        
    }
    



}



export const getSchoolNames=()=>async(dispatch)=>{
    try {
        const {data}=await api.allInstitutes()
        console.log('data', data)

    dispatch({
        type:ALL_INSTITUTE_NAME,
        payload:data
    })
    
}catch (err) {
    const message = err?.response?.data?.message
    ? err.response.data.message
    : 'Something went wrong';
  console.log(message);
    
}
}