
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import BoardsPage from './components/BoardsPage';
import LoginPage from './components/LoginPage';
import StandardsPage from './components/StandardsPage';
import SubjectsPage from './components/SubjectsPage';
import ChaptersPage from './components/ChaptersPage';
import EventsPage from './components/EventsPage';
import WorkshopPage from './components/WorkshopPage';
import TestimonialsPage from './components/TestimonialsPage';
import BlogsPage from './components/BlogsPage';
import UsersListPage from './components/UsersListPage';
import Lectures from './components/Lectures';
import Teachers from './components/Teachers';
import ExamsPage from './components/ExamsPage';

import ProjectsPage from './components/ProjectsPage';
import BulkListPage from './components/BulkListPage';
import InvoicePage from './components/InvoicePage';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/boards" element={<BoardsPage />} />
        <Route exact path="/standards" element={<StandardsPage />} />
        <Route exact path="/subjects" element={<SubjectsPage />} />
        <Route exact path="/chapters" element={<ChaptersPage />} />
        <Route exact path="/events" element={<EventsPage />} />
      
        <Route exact path="/testimonials" element={<TestimonialsPage />} />
        <Route exact path="/blogs" element={<BlogsPage />} />
        <Route exact path="/users-list" element={<UsersListPage />} />
        <Route exact path='/lecture-requests' element={<Lectures/>} />
        <Route exact path='/teachers' element={<Teachers/>}  />
        <Route exact path='/exams' element={<ExamsPage/>}  />
        <Route exact path='/projects' element={<ProjectsPage/>} />
        <Route exact path='/bulk-list' element={<BulkListPage/>} />
        <Route exact path='/invoice' element={<InvoicePage/>}/>     
         </Routes>
    </>
  );
};

export default App;
