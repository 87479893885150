export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_ADMIN_DETAILS = 'SET_ADMIN_DETAILS';

export const GET_ALL_BOARDS = 'GET_ALL_BOARDS';
export const ADD_BOARD = 'ADD_BOARD';
export const DELETE_BOARD = 'DELETE_BOARD';

export const GET_ALL_STANDARDS = 'GET_ALL_STANDARDS';
export const ADD_STANDARD = 'ADD_STANDARD';
export const DELETE_STANDARD = 'DELETE_STANDARD';

export const GET_ALL_SUBJECTS = 'GET_ALL_SUBJECTS';
export const ADD_SUBJECT = 'ADD_SUBJECT';
export const DELETE_SUBJECT = 'DELETE_SUBJECT';
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const ADD_CHAPTER = 'ADD_CHAPTER';
export const DELETE_CHAPTER = 'DELETE_CHAPTER';
export const UPDATE_CHAPTER = 'UPDATE_CHAPTER';

export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const ADD_EVENT = 'ADD_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DECLARE_RESULT = 'DECLARE_RESULT';

export const GET_ALL_WORKSHOPS = 'GET_ALL_WORKSHOPS';
export const ADD_WORKSHOP = 'ADD_WORKSHOP';
export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const DELETE_WORKSHOP = 'DELETE_WORKSHOP';

export const GET_ALL_TESTIMONIALS = 'GET_ALL_TESTIMONIALS';
export const ADD_TESTIMONIAL = 'ADD_TESTIMONIAL';
export const UPDATE_TESTIMONIAL = 'UPDATE_TESTIMONIAL';
export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL';

export const GET_ALL_BLOGS = 'GET_ALL_BLOGS';
export const ADD_BLOG = 'ADD_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER_PURCHASES = 'GET_USER_PURCHASES';


export const GET_LECTURE_PRICE='GET_LECTURE_PRICE';
export const ADD_LECTURE_PRICES='ADD_LECTURE_PRICES';
export const UPDATE_LECTURE_PRICES='UPDATE_LECTURE_PRICES';
export const DELETE_LECTURE_PRICE='DELETE_LECTURE_PRICE';

export const GET_TEACHER_DETAILS='GET_TEACHER_DETAILS';
export const CREATE_TEACHERS='CREATE_TEACHERS';
export const UPDATE_TEACHER='UPDATE_TEACHER';
export const DELETE_TEACHER='DELETE_TEACHER';

export const CREATE_PROJECT='CREATE_PROJECT';
export const GET_PROJECT='GET_PROJECT';
export const UPDATE_PROJECT='UPDATE_PROJECT';
export const DELETE_PROJECT='DELETE_PROJECT';

/*export const GET_ALL_EXAMS = 'GET_ALL_EXAMS';
export const ADD_EXAMS = 'ADD_EXAMS';
export const DELETE_EXAMS = 'DELETE_EXAMS';
export const UPDATE_EXAMS = 'UPDATE_EXAMS';
//export const ADD_CHAPTER = 'ADD_CHAPTER';
//export const DELETE_CHAPTER = 'DELETE_CHAPTER';
//export const UPDATE_CHAPTER = 'UPDATE_CHAPTER';*/

export const GET_ALL_EXAMS = 'GET_ALL_EXAMS';
export const ADD_EXAM = 'ADD_EXAM';
export const DELETE_EXAM = 'DELETE_EXAM';
export const UPDATE_EXAM = 'UPDATE_EXAM';
export const ADD_EXAM_CHAPTER = 'ADD_EXAM_CHAPTER';
export const DELETE_EXAM_CHAPTER = 'DELETE_EXAM_CHAPTER';
export const UPDATE_EXAM_CHAPTER = 'UPDATE_EXAM_CHAPTER';



export const GET_ALL_USERS_BY_TEACHER="GET_ALL_USERS_BY_TEACHER";
export const ALL_PENDING_USERS='ALL_PENDING_USERS';
export const CREATE_BULK_USER='CREATE_BULK_USER';
export const CREATE_SINGLE_USER_BY_TEACHER='CREATE_SINGLE_USER_BY_TEACHER';



export const ALL_INSTITUTE_NAME="ALL_INSTITUTE_NAME"