import {
  GET_ALL_TESTIMONIALS,
  ADD_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
  DELETE_TESTIMONIAL
} from '../actionTypes';

const initialState = {
  allTestimonials: [],
};

const testimonialReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TESTIMONIALS:
      return {
        ...state,
        allTestimonials: action?.payload,
      };

    case DELETE_TESTIMONIAL:
      return {
        ...state,
        allTestimonials: state.allTestimonials.filter(testimonial => testimonial.id !== action.payload)
      };

    case ADD_TESTIMONIAL:
    case UPDATE_TESTIMONIAL:
      return state;

    default:
      return state;
  }
};

export default testimonialReducer;