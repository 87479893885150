import { GET_ALL_USERS_BY_TEACHER,ALL_PENDING_USERS} from '../actionTypes';

const initialState={
pendingData:null,
legitUserData:null
}
const BulkUserReducer = (state = initialState, action) => {
    switch(action.type){
        case ALL_PENDING_USERS:
            return{
                ...state,
                pendingData: action?.payload
            }
        case GET_ALL_USERS_BY_TEACHER:
            return{
                ...state,
                legitUserData: action?.payload
            }

         default:
            return state;
    }
  };
export default BulkUserReducer;