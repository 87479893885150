import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { addLecturePrices, deleteLecturePrice, getLecturePrice, updateLecturePrice } from '../redux/actions/lectureActions';
import useStyles from '../styles/EventsPage';

const initialDetails = {
  standards: '',
  packageName: '',
  monthly_prices:'',
  quarterly_prices: '',
  half_yearly_prices: '',
  yearly_prices:''
};

const Lectures = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addWorkshopModal, setAddWorkshopModal] = useState(false);
  const [workshopDetails, setWorkshopDetails] = useState(initialDetails);
  const [editableWorkshop, setEditableWorkshop] = useState(null);
  const [editWorkshopModal, setEditWorkshopModal] = useState(false);

  const { getLecturePrices } = useSelector((store) => store.lectureReducer);
  const { allStandards } = useSelector((store) => store.standardReducer);

  const Package = ['single', 'double', 'triple', 'premium'];
  

  useEffect(() => {
   
      dispatch(getLecturePrice());
  
  }, [dispatch]);

  const rowCount = getLecturePrices?.length || 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    console.log('id', id)
    dispatch(deleteLecturePrice(id)).then(()=>
    dispatch(getLecturePrice()))
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setWorkshopDetails((prevState) => {
      const updatedDetails = { ...prevState, [name]: value };
      
      // Log the updated state
      console.log(updatedDetails);
  
      return updatedDetails;
    });
  };
  

  const handleEditDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditableWorkshop((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEditClick = (workshop) => {
    setEditableWorkshop({ ...workshop });
    setEditWorkshopModal(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const data = {
      standards: workshopDetails.standards,
      packageName: workshopDetails.packageName,
      monthly_prices: workshopDetails.monthly_prices,
      quarterly_prices: workshopDetails.quarterly_prices,
      half_yearly_prices: workshopDetails.half_yearly_prices,
      yearly_prices: workshopDetails.yearly_prices,
      
    };
  
    console.log('Data:', data);
  
    dispatch(addLecturePrices(data)).then(()=>{
      setWorkshopDetails(initialDetails);
      setAddWorkshopModal(false);
      dispatch(getLecturePrice());
    })
  
  };
  

  const handleSaveChanges = (e) => {
    e.preventDefault();

    const data = {
      standards: editableWorkshop.standards,
      packageName: editableWorkshop.packageName,
      monthly_prices: editableWorkshop.monthly_prices,
      quarterly_prices: editableWorkshop.quarterly_prices,
      half_yearly_prices: editableWorkshop.half_yearly_prices,
      yearly_prices: editableWorkshop.yearly_prices,
      
    };

    dispatch(updateLecturePrice(editableWorkshop.id, data))
    .then(() => {
      setEditWorkshopModal(false);
      dispatch(getLecturePrice());  // Fetch updated lecture prices
    });
  };

  return (
    <MiniDrawer>
      <div className={classes.EventsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddWorkshopModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="Workshop Table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Class
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Package Name
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Monthly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                   Quarterly Price
                  </TableCell>
               
                  <TableCell className={classes.tableHeaderCell} align="center">
                   Yearly Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getLecturePrices
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={item.id} hover tabIndex={-1} className={classes.tableRow}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{item?.standards}</TableCell>
                      <TableCell align="center">{item?.packageName}</TableCell>
                      <TableCell align="center">{item?.monthly_prices}</TableCell>
                      <TableCell align="center">{item?.quarterly_prices}</TableCell>
                      <TableCell align="center">{item?.yearly_prices}</TableCell>
                      <TableCell align="center">
                        <EditIcon
                          onClick={() => handleEditClick(item)}
                          className={classes.editIcon}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className={classes.deleteIcon}
                          onClick={() => handleDelete(item.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* Add Workshop Modal */}
        {addWorkshopModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddWorkshopModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Add Prices</h1>
              <form className={classes.eventModalForm} onSubmit={handleSubmit}>
                <div className={classes.inputRow}>
                  <TextField
                   select
                    name="standards"
                    required
                    label="Class"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.standards}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  >

                    {allStandards?.map((item)=>(
                      <MenuItem key={item.id} value={item.class}>
                        {item?.class}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    name="packageName"
                    required
                    label="Package Name"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.packageName}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  >
                    {Package.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    
                    name="monthly_prices"
                    required
                    label="Monthly Prices"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.monthly_prices}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                  
                  <TextField
                    name="quarterly_prices"
                    required
                    label="Quarterly Prices"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.quarterly_prices}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                </div>
                <div className={classes.inputRow}>
                  
                  <TextField
                    name="yearly_prices"
                    required
                    label="Yearly Prices"
                    size="small"
                    variant="outlined"
                    value={workshopDetails.yearly_prices}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                </div>
                <Button
                  className={classes.eventModalButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  Save
                </Button>
              </form>
            </div>
          </div>
        )}

        {/* Edit Workshop Modal */}
        {editWorkshopModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setEditWorkshopModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Edit Workshop</h1>
              <form className={classes.eventModalForm} onSubmit={handleSaveChanges}>
                <div className={classes.inputRow}>
                  <TextField
                  select
                    name="standards"
                    required
                    label="Class"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop?.standards || ''}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  >
                    {allStandards?.map((item)=>(
                      <MenuItem key={item.id} value={item.class}>
                        {item?.class}
                      </MenuItem>
                    ))}
                    </TextField>
                  <TextField
                    select
                    name="packageName"
                    required
                    label="Package Name"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop?.packageName || ''}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  >
                    {Package.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className={classes.inputRow}>
                  <TextField  
                    name="monthly_prices"
                    required
                    label="Monthly Prices"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop.monthly_prices}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  
                  <TextField
                    name="quarterly_prices"
                    required
                    label="Quarterly Prices"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop.quarterly_prices}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                </div>
                <div className={classes.inputRow}>
                  
                  
                  <TextField
                    name="yearly_prices"
                    required
                    label="Yearly Prices"
                    size="small"
                    variant="outlined"
                    value={editableWorkshop.yearly_prices}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                </div>
                <Button
                  className={classes.eventModalButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SaveIcon />}
                  type="submit"
                >
                  Save Changes
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default Lectures;
 