import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  Input,
  MenuItem,
} from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  getAllTestimonials,
  addTestimonial,
  updateTestimonial,
  deleteTestimonial,
} from '../redux/actions/testimonialActions';
import useStyles from '../styles/EventsPage';

const initialDetails = {
  firstname: '',
  lastname: '',
  review: '',
  board: '',
  stars: '',
  img: null,
};

const TestimonialsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addTestimonialModal, setAddTestimonialModal] = useState(false);
  const [testimonialDetails, setTestimonialDetails] = useState(initialDetails);
  const [selectedTestimonial, setSelectedTestimonial] = useState('');
  const [editTestimonialModal, setEditTestimonialModal] = useState(false);

  const { allTestimonials } = useSelector((store) => store.testimonialReducer);
  const { allBoards } = useSelector((store) => store.boardReducer);

  useEffect(() => {
    if (allTestimonials.length === 0) {
      dispatch(getAllTestimonials());
    }
  }, [dispatch, allTestimonials]);

  const rowCount = allTestimonials.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    dispatch(deleteTestimonial(id));
  };

  const handleDetailsChange = (e) => {
    const { name } = e.target;
    setTestimonialDetails({ ...testimonialDetails, [name]: e.target.value });
  };

  const handleEditDetailsChange = (e) => {
    const { name } = e.target;
    setSelectedTestimonial({ ...selectedTestimonial, [name]: e.target.value });
  };

  const handleImageChange = (e) => {
    let file = e.target.files[0];
    setTestimonialDetails({ ...testimonialDetails, img: file });
  };

  const handleEditImageChange = (e) => {
    let file = e.target.files[0];
    setSelectedTestimonial({ ...selectedTestimonial, img: file });
  };

  const handleEditClick = (testimonial) => {
    setSelectedTestimonial({ ...testimonial });
    setEditTestimonialModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("firstName", testimonialDetails.firstname);
    formData.append("lastName", testimonialDetails.lastname);
    formData.append("review", testimonialDetails.review);
    formData.append("board", testimonialDetails.board);
    formData.append("stars", testimonialDetails.stars);
    formData.append("image", testimonialDetails.img);

    dispatch(addTestimonial(formData));

    setTestimonialDetails(initialDetails);
    setAddTestimonialModal(false);
    dispatch(getAllTestimonials());
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("firstname", selectedTestimonial.firstname);
    formData.append("lastname", selectedTestimonial.lastname);
    formData.append("review", selectedTestimonial.review);
    formData.append("board", selectedTestimonial.board);
    formData.append("stars", selectedTestimonial.stars);
    formData.append("image", selectedTestimonial.img);

    dispatch(updateTestimonial(selectedTestimonial.id, formData));

    setEditTestimonialModal(false);
    dispatch(getAllTestimonials());
  };

  return (
    <MiniDrawer>
      <div className={classes.EventsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddTestimonialModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Testimonial Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Name
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Board
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Rating
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Review
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTestimonials?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((testimonial, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={classes.tableRow}
                      >
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>
                          {testimonial.firstname + ' ' + testimonial.lastname}
                        </TableCell>
                        <TableCell align='center'>{testimonial.board}</TableCell>
                        <TableCell align='center'>{testimonial.stars}</TableCell>
                        <TableCell align='center'>{testimonial.review}</TableCell>
                        <TableCell align='center'>
                          <EditIcon
                            onClick={() => handleEditClick(testimonial)}
                            className={classes.editIcon}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(testimonial.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {addTestimonialModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddTestimonialModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Add Testimonial</h1>
              <form className={classes.eventModalForm} onSubmit={handleSubmit}>
                <div className={classes.inputRow}>
                  <TextField
                    name="firstname"
                    required
                    label="First Name"
                    size="small"
                    variant="outlined"
                    value={testimonialDetails.firstname}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                  <TextField
                    name="lastname"
                    required
                    label="Last Name"
                    size="small"
                    variant="outlined"
                    value={testimonialDetails.lastname}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    select
                    required
                    name='board'
                    label='Board'
                    variant='outlined'
                    size='small'
                    value={testimonialDetails.board}
                    onChange={handleDetailsChange}
                    className={classes.eventModalInput}
                  >
                    {allBoards.map((board) => (
                      <MenuItem value={board.name}>{board.name}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    name="stars"
                    required
                    label="Rating"
                    size="small"
                    variant="outlined"
                    value={testimonialDetails.stars}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  >
                    {[1, 2, 3, 4, 5].map((num) => (
                      <MenuItem value={num}>{num}</MenuItem>
                    ))}
                  </TextField>
                </div>
                <p className={classes.testimonialImgText}>Testimonial Image</p>
                <Input
                  fullWidth
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  onChange={handleImageChange}
                  className={classes.testimonialInputImage}
                />
                <TextField
                  required
                  fullWidth
                  name="review"
                  label="Review"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={testimonialDetails.review}
                  onChange={handleDetailsChange}
                  className={classes.testimonialReview}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
        {editTestimonialModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setEditTestimonialModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Edit Testimonial</h1>
              <form className={classes.eventModalForm} onSubmit={handleSaveChanges}>
                <div className={classes.inputRow}>
                  <TextField
                    name="firstname"
                    required
                    label="First Name"
                    size="small"
                    variant="outlined"
                    value={selectedTestimonial.firstname}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  <TextField
                    name="lastname"
                    required
                    label="Last Name"
                    size="small"
                    variant="outlined"
                    value={selectedTestimonial.lastname}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    select
                    required
                    name='board'
                    label='Board'
                    variant='outlined'
                    size='small'
                    value={selectedTestimonial.board}
                    onChange={handleEditDetailsChange}
                    className={classes.eventModalInput}
                  >
                    {allBoards.map((board) => (
                      <MenuItem value={board.name}>{board.name}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    name="stars"
                    required
                    label="Rating"
                    size="small"
                    variant="outlined"
                    value={selectedTestimonial.stars}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  >
                    {[1, 2, 3, 4, 5].map((num) => (
                      <MenuItem value={num}>{num}</MenuItem>
                    ))}
                  </TextField>
                </div>
                <p className={classes.testimonialImgText}>Testimonial Image</p>
                <Input
                  fullWidth
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  onChange={handleEditImageChange}
                  className={classes.testimonialInputImage}
                />
                <TextField
                  required
                  fullWidth
                  name="review"
                  label="Review"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={selectedTestimonial.review}
                  onChange={handleEditDetailsChange}
                  className={classes.testimonialReview}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default TestimonialsPage;