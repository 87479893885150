import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  Input,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MiniDrawer from "./MiniDrawer";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  addEvent,
  updateEvent,
  deleteEvent,
} from "../redux/actions/eventActions";
import useStyles from "../styles/ProjectsPage";
import {
  getProjects,
  createProject,
  updateProject,
  removeProject,
} from "../redux/actions/projectActions";

// const initialDetails = {
//   project_name: '',
//   groupPrice: '',
//   soloPrice: '',
//   project_desc: '',
//   img_url: null,
//   startTime: '',
//   endTime: '',
//   videoSubmission: false,
//   paid: true,
//   price: '',
//   videoLimit: '',
//   want_to_send_to_approval: false,
//   imageLimit: '',
//   pdfSubmission: false,
//   pdfLimit: '',
// };

const initialDetails = {
  project_name: "",
  project_desc: "",
  img_url: null,
  video_url: null,
  startTime: "",
  endTime: "",
  price: "",
  paid: true,
  want_to_send_to_approval: false,
  standard: "",
  section: "",
  videoLimit: "",
  imageSubmission: false,
  imageLimit: "",
  pdfSubmission: false,
  pdfLimit: "",
  videoSubmission: false,
  isVideo: false,
};

const ProjectsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addProjectsModel, setaddProjectsModel] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [projectDetails, setprojectDetails] = useState(initialDetails);
  const [result, setResult] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedProject, setselectedProject] = useState("");
  const [editableProject, seteditableProject] = useState("");
  const [editProjectModal, seteditProjectModal] = useState(false);

  const { projectData } = useSelector((store) => store.projectReducer);

  const { allStandards } = useSelector((store) => store.standardReducer);

  useEffect(() => {
    if (projectData?.length === 0) {
      // Only fetch if no data is present
      dispatch(getProjects());
    }
  }, [dispatch, projectData]);
  const rowCount = projectData?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    dispatch(removeProject(id)).then(() => {
      dispatch(getProjects());
    });
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;

    setprojectDetails({ ...projectDetails, [name]: value });
  };

  const handleEditDetailsChange = (e) => {
    const { name } = e.target;
    seteditableProject({ ...editableProject, [name]: e.target.value });
  };

  const handleDescChange = (value) => {
    setprojectDetails({ ...projectDetails, project_desc: value });
  };

  const handleEditDescChange = (value) => {
    seteditableProject({ ...editableProject, project_desc: value });
  };

  const handleImageChange = (e) => {
    let file = e.target.files[0];
    setprojectDetails({ ...projectDetails, img_url: file });
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setprojectDetails({ ...projectDetails, video_url: file });
  };

  const handleEditImageChange = (e) => {
    let file = e.target.files[0];
    seteditableProject({ ...editableProject, img_url: file });
  };

  const handleEditVideoChange = (e) => {
    let file = e.target.files[0];
    seteditableProject({ ...editableProject, video_url: file });
  };
  const handleToggleChange = (e) => {
    const { name } = e.target;
    setprojectDetails((prevDetails) => {
      const newValue = !prevDetails[name];

      return { ...prevDetails, [name]: newValue };
    });
  };

  const handleResultClick = (event) => {
    setResultModal(true);
    setselectedProject(event.id);
    setResult(event.result);
  };

  const handleEditToggleChange = (e) => {
    const { name } = e.target;
    seteditableProject({ ...editableProject, [name]: !editableProject[name] });
  };

  const handleEditClick = (event) => {
    const eventObj = { ...event };

    eventObj.paid = eventObj.price === "FREE" ? false : true;

    seteditableProject(eventObj);
    seteditProjectModal(true);
  };

  const sectionOptions = ["a", "b", "c", "d", "e", "f", "g", "h"];
  // console.log('projectData', projectData)
  const handleSaveChanges = (e) => {
    e.preventDefault();

    const [syyyy, smm, sdd] = editableProject.startTime.split("-");
    const [eyyyy, emm, edd] = editableProject.endTime.split("-");

    const formData = new FormData();

    formData.append("project_name", editableProject.project_name); // Use editableProject
    formData.append("project_desc", editableProject.project_desc);
   
      // Only append if img_url exists
      formData.append("file", editableProject.img_url);
    formData.append("file",editableProject?.video_url)
    formData.append("paid", editableProject.paid);
    formData.append(
      "price",
      editableProject.paid ? editableProject.price : "FREE"
    );
    formData.append(
      "want_to_send_to_approval",
      editableProject.want_to_send_to_approval
    );
    formData.append("standard", editableProject.standard);
    formData.append("section", editableProject.section);
    formData.append("videoSubmission", editableProject.videoSubmission);

    formData.append(
      "videoLimit",
      editableProject.videoSubmission ? editableProject.videoLimit : "0"
    );
    formData.append("imageSubmission", editableProject.imageSubmission);
    formData.append("isVideo", editableProject.isVideo);
    formData.append(
      "imageLimit",
      editableProject.imageSubmission ? editableProject.imageLimit : "0"
    );
    formData.append("pdfSubmission", editableProject.pdfSubmission);
    formData.append(
      "pdfLimit",
      editableProject.pdfSubmission ? editableProject.pdfLimit : "0"
    );
    formData.append(
      "startTime",
      new Date(parseInt(syyyy), parseInt(smm) - 1, parseInt(sdd) + 1)
    );
    formData.append(
      "endTime",
      new Date(parseInt(eyyyy), parseInt(emm) - 1, parseInt(edd) + 1)
    );

    dispatch(updateProject(editableProject.id, formData)).then(() => {
      seteditProjectModal(false);
      dispatch(getProjects());
    });
  };

  console.log("projectDetails", projectDetails);
  const handleSubmit = (e) => {
    e.preventDefault();
    const [syyyy, smm, sdd] = projectDetails.startTime.split("-");
    const [eyyyy, emm, edd] = projectDetails.endTime.split("-");
    const formData = new FormData();
    formData.append("project_name", projectDetails.project_name);
    formData.append("project_desc", projectDetails.project_desc);
    formData.append("file", projectDetails.img_url);
    formData.append("file",projectDetails?.video_url);
    formData.append("paid", projectDetails?.paid);
    formData.append(
      "price",
      projectDetails.paid ? projectDetails.price : "FREE"
    );
    formData.append(
      "want_to_send_to_approval",
      projectDetails.want_to_send_to_approval
    );
    formData.append("standard", projectDetails.standard);
    formData.append("section", projectDetails.section);
    formData.append("videoSubmission", projectDetails.videoSubmission);

    formData.append(
      "videoLimit",
      projectDetails.videoSubmission ? projectDetails.videoLimit : "0"
    );
    formData.append("imageSubmission", projectDetails.imageSubmission);
    formData.append("isVideo", projectDetails.isVideo);
    formData.append(
      "imageLimit",
      projectDetails.imageSubmission ? projectDetails.imageLimit : "0"
    );
    formData.append("pdfSubmission", projectDetails.pdfSubmission);
    formData.append(
      "pdfLimit",
      projectDetails.pdfSubmission ? projectDetails.pdfLimit : "0"
    );
    formData.append(
      "startTime",
      new Date(parseInt(syyyy), parseInt(smm) - 1, parseInt(sdd) + 1)
    );
    formData.append(
      "endTime",
      new Date(parseInt(eyyyy), parseInt(emm) - 1, parseInt(edd) + 1)
    );

    console.log("formData", formData);
    dispatch(createProject(formData)).then(() => {
      setprojectDetails(initialDetails);
      setaddProjectsModel(false);
      dispatch(getProjects());
    });
  };

  return (
    <MiniDrawer>
      <div className={classes.ProjectsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setaddProjectsModel(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="Events Table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Project Title
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Class
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Section
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell} align="center">
                    Start Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    End Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Price
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Sent to Approval
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Status
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(projectData) && projectData?.length > 0 ? (
                  projectData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((event, index) => {
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          className={classes.tableRow}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {event?.project_name}
                          </TableCell>
                          <TableCell align="center">
                            {event?.standard}
                          </TableCell>
                          <TableCell align="center">{event?.section}</TableCell>
                          <TableCell align="center">
                            {event?.startTime}
                          </TableCell>
                          <TableCell align="center">{event?.endTime}</TableCell>
                          <TableCell align="center">{event?.price}</TableCell>
                          <TableCell align="center">
                            {event?.want_to_send_to_approval === 0
                              ? "No"
                              : "Yes"}
                          </TableCell>

                          <TableCell align="center">
                            {event?.approval === 0 ? (
                              <Chip
                                label="Not Approved"
                                color="secondary"
                                variant="outlined"
                              />
                            ) : (
                              <Chip
                                label="Approved"
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </TableCell>

                          <TableCell align="center">
                            <EditIcon
                              onClick={() => handleEditClick(event)}
                              className={classes.editIcon}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <DeleteIcon
                              className={classes.deleteIcon}
                              onClick={() => handleDelete(event.id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      EMPTY
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={typeof rowCount === "undefined" ? 0 : rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {addProjectsModel && (
          <div className={classes.projectsModal}>
            <div className={classes.projectsModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setaddProjectsModel(false)}
              />
              <h1 className={classes.projectsModalTitle}>Add Project</h1>
              <form
                className={classes.projectsModalForm}
                onSubmit={handleSubmit}
              >
                <div className={classes.inputRow}>
                  <TextField
                    name="project_name"
                    required
                    fullWidth
                    label="Project Title"
                    size="small"
                    variant="outlined"
                    value={projectDetails.project_name}
                    className={classes.projectsModalInput}
                    onChange={handleDetailsChange}
                  />
                </div>

                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isVideo"
                        checked={projectDetails.isVideo}
                        onChange={handleToggleChange}
                        color="primary"
                      />
                    }
                    label="Upload video file?"
                  />
                  <div className={classes.imgDiv}>
                    <p className={classes.descText}>
                      {projectDetails?.isVideo === false
                        ? `Project image`
                        : `Project video`}
                    </p>
                    <Input
                      type="file"
                      inputProps={{
                        accept:
                          projectDetails?.isVideo === false
                            ? "image/*"
                            : "video/*", // Conditionally accept image or video
                      }}
                      fullWidth
                      className={classes.projectsImgInput}
                      onChange={
                        projectDetails?.isVideo === false
                          ? handleImageChange
                          : handleVideoChange
                      } // Conditionally use the appropriate handler
                    />
                  </div>
                </div>

                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="paid"
                        checked={projectDetails.paid}
                        onChange={handleToggleChange}
                        color="primary"
                      />
                    }
                    label="Is it paid"
                  />
                  {projectDetails.paid && (
                    <TextField
                      type="number"
                      name="price"
                      required
                      label="Price"
                      size="small"
                      variant="outlined"
                      value={projectDetails?.price}
                      className={classes.projectsModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div>
                <p className={classes.descText}>Description</p>
                <ReactQuill
                  value={projectDetails?.project_desc}
                  onChange={handleDescChange}
                  className={classes.descBox}
                />
                <div className={classes.inputRow}>
                  <TextField
                    name="standard"
                    required
                    label="Class"
                    size="small"
                    select
                    variant="outlined"
                    value={projectDetails?.standard}
                    onChange={handleDetailsChange}
                    className={classes.projectsModalInput}
                  >
                    {allStandards?.map((item) => (
                      <MenuItem key={item.class} value={item.class}>
                        {item.class}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    name="section"
                    required
                    label="Section"
                    select
                    size="small"
                    variant="outlined"
                    value={projectDetails?.section}
                    onChange={handleDetailsChange}
                    className={classes.projectsModalInput}
                  >
                    {sectionOptions?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className={classes.inputRow}>
                  <TextField
                    name="startTime"
                    required
                    label="Start Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={projectDetails?.startTime}
                    onChange={handleDetailsChange}
                    className={classes.projectsModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    name="endTime"
                    required
                    label="End Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={projectDetails?.endTime}
                    onChange={handleDetailsChange}
                    className={classes.projectsModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="want_to_send_to_approval"
                        checked={projectDetails?.want_to_send_to_approval}
                        onChange={handleToggleChange}
                        color="primary"
                      />
                    }
                    label="Want to send for a approval?"
                  />
                </div>

                {/* <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="imageSubmission"
                        checked={projectDetails.imageSubmission}
                        onChange={handleToggleChange}
                        color="primary"
                      />
                    }
                    label="Is image allowed"
                  />
                  {projectDetails.imageSubmission && (
                    <TextField
                      type="number"
                      name="imageLimit"
                      required
                      label="Image Limit"
                      size="small"
                      variant="outlined"
                      value={projectDetails.imageLimit}
                      className={classes.eventModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="videoSubmission"
                        checked={projectDetails.videoSubmission}
                        onChange={handleToggleChange}
                        color="primary"
                      />
                    }
                    label="Is video allowed"
                  />
                  {projectDetails.videoSubmission && (
                    <TextField
                      type="number"
                      name="videoLimit"
                      required
                      label="Video Limit"
                      size="small"
                      variant="outlined"
                      value={projectDetails.videoLimit}
                      className={classes.eventModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="pdfSubmission"
                        checked={projectDetails.pdfSubmission}
                        onChange={handleToggleChange}
                        color="primary"
                      />
                    }
                    label="Is pdf allowed"
                  />
                  {projectDetails.pdfSubmission && (
                    <TextField
                      type="number"
                      name="pdfLimit"
                      required
                      label="File Limit"
                      size="small"
                      variant="outlined"
                      value={projectDetails.pdfLimit}
                      className={classes.eventModalInput}
                      onChange={handleDetailsChange}
                    />
                  )}
                </div> */}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.projectsModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
        {editProjectModal && (
          <div className={classes.projectsModal}>
            <div className={classes.projectsModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => seteditProjectModal(false)}
              />
              <h1 className={classes.projectsModalTitle}>Edit Project</h1>
              <form
                className={classes.projectsModalForm}
                onSubmit={handleSaveChanges}
              >
                <div className={classes.inputRow}>
                  <TextField
                    name="project_name"
                    required
                    label="Project Title"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={editableProject.project_name}
                    className={classes.projectsModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  
                </div>

                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isVideo"
                        checked={editableProject.isVideo}
                        onChange={handleEditToggleChange}
                        color="primary"
                      />
                    }
                    label="Upload video file?"
                  />
                  <div className={classes.imgDiv}>
                    <p className={classes.descText}>
                      {editableProject?.isVideo === false
                        ? `Project image`
                        : `Project video`}
                    </p>
                    <Input
                      type="file"
                      inputProps={{
                        accept:
                        editableProject?.isVideo === false
                            ? "image/*"
                            : "video/*", // Conditionally accept image or video
                      }}
                      
                      className={classes.projectsImgInput}
                      onChange={
                        editableProject?.isVideo === false
                          ? handleEditImageChange
                          : handleEditVideoChange
                      } 
                    />
                  </div>
                </div>

                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="paid"
                        checked={editableProject.paid}
                        onChange={handleEditToggleChange}
                        color="primary"
                      />
                    }
                    label="Is it paid?"
                  />
                  {editableProject?.paid && (
                    <TextField
                      type="number"
                      name="price"
                      required
                      label="Price"
                      size="small"
                      variant="outlined"
                      value={editableProject?.price}
                      className={classes.projectsModalInput}
                      onChange={handleEditDetailsChange}
                    />
                  )}
                </div>
                <p className={classes.descText}>Project Description</p>
                <ReactQuill
                  value={editableProject.project_desc}
                  onChange={handleEditDescChange}
                  className={classes.descBox}
                />

                <div className={classes.inputRow}>
                  <TextField
                    name="standard"
                    required
                    label="Class"
                    size="small"
                    variant="outlined"
                    select
                    value={editableProject?.standard}
                    onChange={handleDetailsChange}
                    className={classes.projectsModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {allStandards?.map((item) => (
                      <MenuItem key={item.class} value={item.class}>
                        {item.class}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    name="section"
                    required
                    label="Section"
                    select
                    size="small"
                    variant="outlined"
                    value={editableProject?.section}
                    onChange={handleDetailsChange}
                    className={classes.projectsModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {sectionOptions?.map((item) => (
                      <Menu key={item} value={item}>
                        {item}
                      </Menu>
                    ))}
                  </TextField>
                </div>
                <div className={classes.inputRow}>
                  <TextField
                    name="startTime"
                    required
                    label="Start Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={editableProject.startTime}
                    onChange={handleEditDetailsChange}
                    className={classes.projectsModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    name="endTime"
                    required
                    label="End Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={editableProject.endTime}
                    onChange={handleEditDetailsChange}
                    className={classes.projectsModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="want_to_send_to_approval"
                        checked={editableProject.want_to_send_to_approval}
                        onChange={handleEditToggleChange}
                        color="primary"
                      />
                    }
                    label="Want to send for approval?"
                  />
                </div>
                {/* <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="imageSubmission"
                        checked={editableProject.imageSubmission}
                        onChange={handleEditToggleChange}
                        color="primary"
                      />
                    }
                    label="Is image allowed"
                  />
                  {editableProject.imageSubmission && (
                    <TextField
                      type="number"
                      name="imageLimit"
                      required
                      label="Image Limit"
                      size="small"
                      variant="outlined"
                      value={editableProject.imageLimit}
                      className={classes.eventModalInput}
                      onChange={handleEditToggleChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="videoSubmission"
                        checked={editableProject.videoSubmission}
                        onChange={handleEditToggleChange}
                        color="primary"
                      />
                    }
                    label="Is video allowed"
                  />
                  {editableProject.videoSubmission && (
                    <TextField
                      type="number"
                      name="videoLimit"
                      required
                      label="Video Limit"
                      size="small"
                      variant="outlined"
                      value={editableProject.videoLimit}
                      className={classes.eventModalInput}
                      onChange={handleEditToggleChange}
                    />
                  )}
                </div>
                <div className={classes.switchRow}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="pdfSubmission"
                        checked={editableProject.pdfSubmission}
                        onChange={handleEditToggleChange}
                        color="primary"
                      />
                    }
                    label="Is pdf allowed"
                  />
                  {editableProject.pdfSubmission && (
                    <TextField
                      type="number"
                      name="pdfLimit"
                      required
                      label="File Limit"
                      size="small"
                      variant="outlined"
                      value={editableProject.pdfLimit}
                      className={classes.eventModalInput}
                      onChange={handleEditToggleChange}
                    />
                  )}
                </div> */}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.projectsModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default ProjectsPage;
