import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
  Input,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getAllBlogs, addBlog, updateBlog, deleteBlog } from '../redux/actions/blogActions';
import useStyles from '../styles/EventsPage';

const initialDetails = {
  topic: '',
  desc: '',
  img: null,
  author: '',
  date: '',
  readingTime: '',
};

const BlogsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addBlogModal, setAddBlogModal] = useState(false);
  const [blogDetails, setBlogDetails] = useState(initialDetails);
  const [editBlogModal, setEditBlogModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState('');

  const { allBlogs } = useSelector((store) => store.blogReducer);

  useEffect(() => {
    if (allBlogs.length === 0) {
      dispatch(getAllBlogs());
    }
  }, [dispatch]);

  const rowCount = allBlogs.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    dispatch(deleteBlog(id));
  };

  const handleDetailsChange = (e) => {
    const { name } = e.target;
    setBlogDetails({ ...blogDetails, [name]: e.target.value });
  };

  const handleEditDetailsChange = (e) => {
    const { name } = e.target;
    setSelectedBlog({ ...selectedBlog, [name]: e.target.value });
  };

  const handleDescChange = (value) => {
    setBlogDetails({ ...blogDetails, desc: value });
  };

  const handleEditDescChange = (value) => {
    setSelectedBlog({ ...selectedBlog, desc: value });
  };

  const handleImageChange = (e) => {
    let file = e.target.files[0];
    setBlogDetails({ ...blogDetails, img: file });
  };

  const handleEditImageChange = (e) => {
    let file = e.target.files[0];
    setSelectedBlog({ ...selectedBlog, img: file });
  };

  const handleEditClick = (blog) => {
    const blogObj = { ...blog };
    blogObj.date = new Date(Date.parse(blog.date)).toISOString().split('T')[0];
    setSelectedBlog(blogObj);
    setEditBlogModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const [yyyy, mm, dd] = blogDetails.date.split('-');
    const formData = new FormData();
    formData.append("topic", blogDetails.topic);
    formData.append("author", blogDetails.author);
    formData.append("desc", blogDetails.desc);
    formData.append("image", blogDetails.img);
    formData.append("readingTime", blogDetails.readingTime);
    formData.append("date", new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd) + 1));

    dispatch(addBlog(formData));

    setBlogDetails(initialDetails);
    setAddBlogModal(false);
    dispatch(getAllBlogs())
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const [yyyy, mm, dd] = selectedBlog.date.split('-');
    const formData = new FormData();
    formData.append("topic", selectedBlog.topic);
    formData.append("author", selectedBlog.author);
    formData.append("desc", selectedBlog.desc);
    formData.append("image", selectedBlog.img);
    formData.append("readingTime", selectedBlog.readingTime);
    formData.append("date", new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd) + 1));

    dispatch(updateBlog(selectedBlog.id, formData));

    setEditBlogModal(false);
  };

  return (
    <MiniDrawer>
      <div className={classes.EventsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddBlogModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Blogs Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Title
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Writer
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Reading Time
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Edit
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allBlogs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((blog, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={classes.tableRow}
                      >
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{blog.topic}</TableCell>
                        <TableCell align='center'>{blog.author}</TableCell>
                        <TableCell align='center'>{blog.readingTime} min</TableCell>
                        <TableCell align='center'>
                          {blog.date.replace('6:30 PM', '')}
                        </TableCell>
                        <TableCell align='center'>
                          <EditIcon
                            onClick={() => handleEditClick(blog)}
                            className={classes.editIcon}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(blog.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {addBlogModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddBlogModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Add Blog</h1>
              <form className={classes.eventModalForm} onSubmit={handleSubmit}>
                <div className={classes.inputRow}>
                  <TextField
                    name="topic"
                    required
                    label="Title"
                    size="small"
                    variant="outlined"
                    value={blogDetails.topic}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                  <TextField
                    name="author"
                    required
                    label="Writer"
                    size="small"
                    variant="outlined"
                    value={blogDetails.author}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                </div>
                <p className={classes.descText}>Description</p>
                <ReactQuill
                  value={blogDetails.desc}
                  onChange={handleDescChange}
                  className={classes.descBox}
                />
                <div className={classes.inputRow}>
                  <TextField
                    type="number"
                    name="readingTime"
                    required
                    label="Reading Time"
                    size="small"
                    variant="outlined"
                    value={blogDetails.readingTime}
                    className={classes.eventModalInput}
                    onChange={handleDetailsChange}
                  />
                  <TextField
                    name="date"
                    required
                    label="Blog Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={blogDetails.date}
                    onChange={handleDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <p className={classes.descText}>Blog Image</p>
                <Input
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  fullWidth
                  className={classes.eventImgInput}
                  onChange={handleImageChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
        {editBlogModal && (
          <div className={classes.eventModal}>
            <div className={classes.eventModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setEditBlogModal(false)}
              />
              <h1 className={classes.eventModalTitle}>Edit Blog</h1>
              <form className={classes.eventModalForm} onSubmit={handleSaveChanges}>
                <div className={classes.inputRow}>
                  <TextField
                    name="topic"
                    required
                    label="Title"
                    size="small"
                    variant="outlined"
                    value={selectedBlog.topic}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  <TextField
                    name="author"
                    required
                    label="Writer"
                    size="small"
                    variant="outlined"
                    value={selectedBlog.author}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                </div>
                <p className={classes.descText}>Description</p>
                <ReactQuill
                  value={selectedBlog.desc}
                  onChange={handleEditDescChange}
                  className={classes.descBox}
                />
                <div className={classes.inputRow}>
                  <TextField
                    type="number"
                    name="readingTime"
                    required
                    label="Reading Time"
                    size="small"
                    variant="outlined"
                    value={selectedBlog.readingTime}
                    className={classes.eventModalInput}
                    onChange={handleEditDetailsChange}
                  />
                  <TextField
                    name="date"
                    required
                    label="Blog Date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={selectedBlog.date}
                    onChange={handleEditDetailsChange}
                    className={classes.eventModalInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <p className={classes.descText}>Blog Image</p>
                <Input
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  fullWidth
                  className={classes.eventImgInput}
                  onChange={handleEditImageChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.eventModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default BlogsPage;
