import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chaptersModal: {
    position: 'fixed',
    zIndex: '10',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  chaptersModalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#fefefe',
    width: '50%',
    borderRadius: '10px',
  },
  closeIcon: {
    float: 'right',
    margin: '0.3rem 0.3rem 0 0',
    cursor: 'pointer',
  },
  chaptersModalContainer: {
    width: '90%',
    margin: '1rem auto'
  },
  chapterDialogTitle: {
    paddingBottom: 0,
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#dd404e'
  },
  editIcon: {
    cursor: 'pointer',
    color: 'grey'
  }
}));