


import {GET_TEACHER_DETAILS, UPDATE_TEACHER,ALL_INSTITUTE_NAME} from '../actionTypes'
const InitialState={
    teacherData:[],
    instituteData:[]
}


const teacherReducer=(state=InitialState,action)=>{
switch(action.type){
    case GET_TEACHER_DETAILS:
        return {...state,
            teacherData:action?.payload
        }

    case UPDATE_TEACHER:
        return {
            ...state,
            teacherData:action?.payload
        }
        case ALL_INSTITUTE_NAME:
            return {
                ...state,
                instituteData:action?.payload
            }
     default:
        return state;
}
}


export default teacherReducer;