import * as api from './api';
import {
  GET_ALL_SUBJECTS,
  ADD_SUBJECT,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
  ADD_CHAPTER,
  UPDATE_CHAPTER,
  DELETE_CHAPTER,
} from '../actionTypes';

export const getAllSubjects = () => async (dispatch) => {
  try {
    const { data } = await api.getAllSubjects();
    dispatch({
      type: GET_ALL_SUBJECTS,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};


export const addSubject = (name) => async (dispatch) => {
  try {
    const response=await api.addSubject(name);
    dispatch({ type: ADD_SUBJECT,payload:response.data });
    dispatch(getAllSubjects());
    
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateSubject = (id, formData) => async (dispatch) => {
  try {
    await api.updateSubject(id, formData);
    dispatch({ type: UPDATE_SUBJECT });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteSubject = (id) => async (dispatch) => {
  try {
    await api.deleteSubject(id);
    dispatch({
      type: DELETE_SUBJECT,
      payload: id,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const addChapter = (formData) => async (dispatch) => {
  try {
   const response= await api.addChapter(formData);
    dispatch({ type: ADD_CHAPTER ,payload:response.data});
    dispatch(getAllSubjects());
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateChapter = (id, formData) => async (dispatch) => {
  try {
   const response= await api.updateChapter(id, formData);
    dispatch({ type: UPDATE_CHAPTER, payload:response.data,id});
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteChapter = (chapterId, subjectId) => async (dispatch) => {
  try {
    await api.deleteChapter(chapterId);
    dispatch({
      type: DELETE_CHAPTER,
      payload: { chapterId, subjectId },
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const uploadNotes = (formData, id) => async (dispatch) => {
  try {
   const data= await api.uploadNotes(formData, id);
    dispatch({ type: UPDATE_CHAPTER,payload:data._id });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const uploadLecture = (formData, id) => async (dispatch) => {
  try {
    await api.uploadLecture(formData, id);
    dispatch({ type: UPDATE_CHAPTER });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};