import {
    GET_ALL_EXAMS,
    ADD_EXAM,
    UPDATE_EXAM,
    DELETE_EXAM,
    //ADD_CHAPTER_TO_EXAM,
    //UPDATE_CHAPTER_IN_EXAM,
    //DELETE_CHAPTER_FROM_EXAM,
  } from '../actionTypes';
  
  const initialState = {
    allExams: [],
  };
  
  const examReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_EXAMS:
        return {
          ...state,
          allExams: action.payload,
        };
  
      case DELETE_EXAM:
        return {
          ...state,
          allExams: state.allExams.filter((exam) => exam.id !== action.payload),
        };

  
        case UPDATE_EXAM:
            return {
              ...state,
              allExams: action?.payload
            };
  
      /*case ADD_CHAPTER_TO_EXAM:
        const { examId, chapter } = action.payload;
        return {
          ...state,
          allExams: state.allExams.map(exam =>
            exam.id === examId
              ? { ...exam, chapters: [...exam.chapters, chapter] }
              : exam
          ),
        };
  
      case UPDATE_CHAPTER_IN_EXAM:
        const { examId: updateExamId, chapter: updatedChapter } = action.payload;
        return {
          ...state,
          allExams: state.allExams.map(exam =>
            exam.id === updateExamId
              ? {
                  ...exam,
                  chapters: exam.chapters.map(chapter =>
                    chapter.id === updatedChapter.id
                      ? { ...chapter, ...updatedChapter }
                      : chapter
                  ),
                }
              : exam
          ),
        };
  
      case DELETE_CHAPTER_FROM_EXAM:
        const { examId: deleteExamId, chapterId } = action.payload;
        return {
          ...state,
          allExams: state.allExams.map(exam =>
            exam.id === deleteExamId
              ? {
                  ...exam,
                  chapters: exam.chapters.filter(chapter => chapter.id !== chapterId),
                }
              : exam
          ),
        };*/
  
      default:
        return state;
    }
  };
  
  export default examReducer;
  