import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  TextField,
} from '@material-ui/core';
import MiniDrawer from './MiniDrawer';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { addBoard, deleteBoard } from '../redux/actions/boardActions';
import useStyles from '../styles/BoardsPage';
import AppBar from '@material-ui/core';
//import BreadCrumbs from './BreadCrumbs';

const BoardsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addBoardModal, setAddBoardModal] = useState(false);
  const [boardName, setBoardName] = useState('');

  const { allBoards } = useSelector((store) => store.boardReducer);

  const rowCount = allBoards.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNameChange = (e) => {
    setBoardName(e.target.value);
  };

  const handleDelete = (id) => {
    dispatch(deleteBoard(id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addBoard(boardName));
    setBoardName('');
    setAddBoardModal(false);
  };

  return (



    
    <MiniDrawer>
      <div className={classes.BoardsPageContainer}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddBoardModal(true)}
          >
            CREATE
          </Button>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='Boards Table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Sr. No.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Board Name
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align='center'>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allBoards?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((board, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        className={classes.tableRow}
                      >
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{board.name}</TableCell>
                        <TableCell align='center'>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(board.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {addBoardModal && (
          <div className={classes.boardModal}>
            <div className={classes.boardModalContent}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setAddBoardModal(false)}
              />
              <h1 className={classes.boardModalTitle}>Add Board</h1>
              <form className={classes.boardModalForm} onSubmit={handleSubmit}>
                <TextField
                  required
                  label="Board name"
                  size="small"
                  variant="outlined"
                  value={boardName}
                  className={classes.boardModalInput}
                  onChange={handleNameChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  className={classes.boardModalButton}
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </MiniDrawer>
  );
};

export default BoardsPage;
