import * as api from './api';
import {
  GET_ALL_WORKSHOPS,
  ADD_WORKSHOP,
  UPDATE_WORKSHOP,
  DELETE_WORKSHOP
} from '../actionTypes';

export const getAllWorkshops = () => async (dispatch) => {
  try {
    const { data } = await api.getAllWorkshops();
    dispatch({
      type: GET_ALL_WORKSHOPS,
      payload: data,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const addWorkshop = (formData) => async (dispatch) => {
  try {
    const {data}=await api.addWorkshop(formData);
    dispatch({ type: ADD_WORKSHOP, payload: data });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const updateWorkshop = (id, formData) => async (dispatch) => {
  try {
    await api.updateWorkshop(id, formData);
    dispatch({ type: UPDATE_WORKSHOP });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};

export const deleteWorkshop = (id) => async (dispatch) => {
  try {
    await api.deleteWorkshop(id);
    dispatch({
      type: DELETE_WORKSHOP,
      payload: id,
    });
  } catch (err) {
    const message = err?.response?.data?.message
      ? err.response.data.message
      : 'Something went wrong';
    console.log(message);
  }
};