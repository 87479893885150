import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;



export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: '1rem'
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  
  },
  hide: {
    display: 'none',
  },
  leftIcon: {
    color: '#4059b5',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerTitle: {
    marginLeft: '1rem',
    fontSize: '1.4rem',
    color: '#4059b5',
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  activeLink: {
    backgroundColor: '#4059b5',
    color: 'white',
  },
  listItem: {
    padding: 0,
  },
  listItemButton: {
    padding: '0.5rem 1rem',
    paddingLeft: '1rem',
    transition: '0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#4059b5',
      color: 'white',
    },
  },
  nested: {
    paddingLeft: '1.5rem',
  },
  doubleNested: {
    paddingLeft: '2.5rem',
  },
  tripleNested: {
    paddingLeft: '3.5rem',
  },
  submenuText: {
    fontSize: '0.9rem'
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  accountCircle: {
    // Add padding and size adjustments for smaller screens if needed
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  menuItem: {
    padding: theme.spacing(2), // Adjust padding for better touch targets on mobile
  },
}));
