import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  NotesModal: {
    position: 'fixed',
    zIndex: '10',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  NotesModalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#ffffff',
    width: '35%',
    borderRadius: '10px',
  },
  closeIcon: {
    float: 'right',
    margin: '0.3rem 0.3rem 0 0',
    cursor: 'pointer',
  },
  NotesModalContainer: {
    width: '70%',
    margin: '1rem auto',
  },
  NotesModaloptionsDiv: {
    borderBottom: '1px solid #d1d9d9',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  NotesModaloption: {
    fontSize: '1.1rem',
    padding: '0.7rem 1rem',
    margin: 0,
    cursor: 'pointer'
  },
  activeOption: {
    borderBottom: '2px solid #4059b5',
  },
  addModifyButton: {
    margin: '1rem'
  },
  modalForm: {
    border: '1px solid #d1d9d9',
    borderRadius: '6px',
    padding: '1.5rem',
    marginBottom: '2rem'
  },
  NotesModalButtonDiv: {
    width: '70%',
    margin: '1.5rem auto 0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  NotesModalCard: {
    width: '70%',
    margin: '0 auto 2rem auto',
    border: '1px solid #d1d9d9',
    borderRadius: '6px',
    paddingBottom: '0.3rem'
  },
  NotesModalCardImageContainer: {
    backgroundColor: '#f8f8f8',
    padding: '0.8rem 0 0.6rem 0',
  },
  NotesModalCardImage: {
    width: '35%',
  },
  NotesModalCardText: {
    fontWeight: '600',
    margin: '0.5rem'
  },
  NotesModalButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }
}));